import React from 'react';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import ZAWConfirmationCheck from 'images/zaw/zaw-confirmation-check.svg';
import { trackEvent } from 'helpers/analytics';

import css from 'styles/components/landing/zaw/zawConfirmationCallout.scss';

export interface IZAWConfirmationCalloutProps {
  slotDetails: {
    heading: string;
    body: string;
    callToActionHeading: string;
    callToActionText: string;
    callToActionLink: string;
    footerlabelText: string;
    footerText: string;
    footerLink: string;
  };
}

export const ZAWConfirmationCallout = (props: IZAWConfirmationCalloutProps) => {
  const {
    slotDetails: {
      heading = 'Thank you for your interest in Zappos at Work!',
      body = 'A team member will be in touch within one business day.',
      callToActionHeading = 'Interested in learning more about us?',
      callToActionText = 'Check out our articles',
      callToActionLink = '/',
      footerlabelText = 'Zappos at Work',
      footerText = 'FAQ',
      footerLink = '/'
    }
  } = props;

  const callToActionOnClick = () => {
    trackEvent(`TE_ZAW_LP2023_FORM_CONFIRMATION_LINK_CLICK`, 'call to action');
  };

  const footerOnClick = () => {
    trackEvent(`TE_ZAW_LP2023_FORM_CONFIRMATION_LINK_CLICK`, 'footer');
  };

  return (
    <div className={css.confirmationContainer}>
      <article className={css.callout}>
        <img className={css.calloutImg} src={ZAWConfirmationCheck} alt="" />
        <h2 className={css.calloutHeading}>{heading}</h2>
        <p className={css.calloutText}>{body}</p>

        <p className={css.callToActionHeading}>{callToActionHeading}</p>
        <a className={css.callToActionLink} onClick={callToActionOnClick} href={callToActionLink}>
          {callToActionText}
        </a>
      </article>
      <div className={css.footerContainer}>
        <p>
          {footerlabelText}{' '}
          <a className={css.footerLink} onClick={footerOnClick} href={footerLink}>
            {footerText}
          </a>
        </p>
      </div>
    </div>
  );
};

export default withErrorBoundary('ZAWConfirmationCallout', ZAWConfirmationCallout);
