import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import { trackEvent } from 'helpers/analytics';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/zaw/zawImageAndCallout.scss';

export interface IZAWImageAndCallout {
  slotName?: string;
  slotDetails: {
    buttonText?: string;
    buttonLink?: string;
    contentBody: string;
    contentHeading: string;
    contentImage?: string;
    order?: string;
    sectionHeading: string;
    themeKey: string;
    animate?: boolean;
  };
}

const THEME_KEYS = {
  DARK: 'DARK',
  LIGHT: 'LIGHT'
};

export const ZAWImageAndCallout = (props: IZAWImageAndCallout) => {
  const {
    slotName,
    slotDetails: {
      contentHeading = 'Hassle-Free Set Up',
      contentBody = 'Your custom account is set up and administered by our safety professionals with no program fees.',
      order,
      themeKey = THEME_KEYS.LIGHT,
      buttonText = 'Meet with an Expert',
      buttonLink,
      contentImage,
      sectionHeading,
      animate = 'true'
    }
  } = props;

  const { search } = useLocation();

  const currentParams = new URLSearchParams(search).toString();
  const shouldAnimate = animate === 'true';

  const [ref, inView] = useInView({
    triggerOnce: true
  });

  const linkWithUTMS = buttonLink?.includes('?')
    ? `${buttonLink}${currentParams.length ? '&' : ''}${currentParams}`
    : `${buttonLink}${currentParams.length ? '?' : ''}${currentParams}`;

  const onClick = () => {
    trackEvent('TE_ZAW_LP2023_IMAGE_AND_CALLOUT_BUTTON_CLICK', slotName);
  };

  return (
    <div className={cn(css.container, { [css.darkBackground]: themeKey === THEME_KEYS.DARK })}>
      {sectionHeading && <h2 className={css.sectionHeading}>{sectionHeading}</h2>}
      <div className={cn(css.contentContainer, { [css.flipContent]: order === 'reverse' })}>
        {!!contentImage && (
          <div className={css.imageContainer}>
            <img className={css.image} src={contentImage} alt="zawUsageShot" />
          </div>
        )}
        <div
          ref={ref}
          className={cn(css.callout, { [css.textOnly]: !contentImage, [css.inView]: shouldAnimate && inView, [css.animate]: shouldAnimate })}
        >
          <h3 className={css.heading}>{contentHeading}</h3>
          <p className={cn(css.body)}>{contentBody}</p>
          {!!(buttonText && buttonLink && linkWithUTMS) && (
            <a className={css.button} onClick={onClick} href={linkWithUTMS} type="button">
              {buttonText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary('ZAWImageAndCallout', ZAWImageAndCallout);
