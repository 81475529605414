import { useReducer } from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import timedFetch from 'middleware/timedFetch';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { trackError } from 'helpers/ErrorUtils';
import { formInputsToObject } from 'helpers/HtmlHelpers';
import { AriaLiveTee } from 'components/common/AriaLive';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { INTERNET_EMAIL } from 'common/regex';
import { DATE_FIELD } from 'constants/formFields';
import { formatDate, parseDate } from 'helpers/dateUtils';

import css from 'styles/components/landing/zapposForm.scss';

const labelAfterInputElements = ['radio', 'checkbox'];

export const FormElement = props => {
  const { testId } = useMartyContext();

  const { tagName, index, required, label, contentWidth, ...attrs } = props;

  // these have to be separate as they're needed for both `attrs` spread operator, and used for other spots in this method
  const { type, value, name } = attrs;

  const inlinecss = {
    width: contentWidth
  };

  const uniqueId = `${name}-${index}`;
  const coercedRequired = required?.toString() === 'true';

  const isLabelAfter = labelAfterInputElements.includes(type);
  const labelElement = <label htmlFor={uniqueId}>{label}</label>;

  switch (tagName) {
    case 'input':
      if (type === 'email') {
        attrs.pattern = INTERNET_EMAIL.toString().slice(1, -1);
      }
      return (
        <p className={css.option} key={uniqueId}>
          {!isLabelAfter && labelElement} {/* render order label/input for non-radios */}
          <input style={inlinecss} required={coercedRequired} data-test-id={testId(name)} id={uniqueId} {...attrs} />
          {isLabelAfter && labelElement} {/* render order input/label instead of label/input for radios */}
        </p>
      );
    case 'textarea':
      return (
        <p className={css.option} key={uniqueId}>
          {labelElement}
          <textarea style={inlinecss} required={coercedRequired} data-test-id={testId(name)} id={uniqueId} {...attrs} />
        </p>
      );
    case 'button':
      /* take type as a property https://github.com/yannickcr/eslint-plugin-react/issues/1846 */
      /* eslint-disable react/button-has-type */
      return (
        <button key={`${value}-${index}`} className={css.submit} data-test-id={testId(name)} {...attrs}>
          {label}
        </button>
      );
    /* eslint-enable */
    case 'date':
      const today = Date.now();
      const { dateFormat, oneDayInMs } = DATE_FIELD;
      const { min, max, defaultValue } = attrs;
      return (
        <p className={css.option} key={uniqueId}>
          {labelElement}
          <input
            {...attrs} /* attrs need to be spread first for the min, max, and default to register */
            type="date"
            data-test-id={testId(name)}
            id={uniqueId}
            min={formatDate(dateFormat, parseDate(min * oneDayInMs + today))}
            defaultValue={formatDate(dateFormat, parseDate(defaultValue * oneDayInMs + today))}
            max={formatDate(dateFormat, parseDate(max * oneDayInMs + today))}
          />
        </p>
      );
  }
};

const initialState = {
  error: false,
  success: false
};

const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const RESET = 'RESET';
function reducer(state, action) {
  switch (action) {
    case ERROR:
      return { error: true, success: false };
    case SUCCESS:
      return { error: false, success: true };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export const ZapposForm = props => {
  const { className, formListeners, parentIsDisabled = false, slotDetails } = props;

  const { testId } = useMartyContext();

  const [{ error, success }, dispatch] = useReducer(reducer, initialState);

  const { controls, heading, successMessage, failureMessage, action, setContentType, method = 'post' } = slotDetails;

  const onSubmit = e => {
    e.preventDefault();
    dispatch(RESET);

    const fetcher = timedFetch(`ZapposForm-${action}`);
    const options = {
      method,
      body: JSON.stringify(formInputsToObject(e.target)),
      headers: setContentType ? { 'Content-Type': setContentType } : {}
    };

    fetcher(action, options)
      .then(fetchErrorMiddleware)
      .then(() => dispatch(SUCCESS))
      .catch(err => {
        dispatch(ERROR);
        trackError('ERROR', `Zappos Form: ${action}`, err);
      });
  };

  return (
    <div className={cn(css.container, className)} data-test-id={testId('zapposForm')}>
      {heading && <h2>{heading}</h2>}

      {!error && success && (
        <AriaLiveTee>
          <p className={css.success}>{successMessage}</p>
        </AriaLiveTee>
      )}

      {error && (
        <AriaLiveTee>
          <p className={css.failure}>{failureMessage}</p>
        </AriaLiveTee>
      )}

      {/* our custom eslint rule doesnt accept method as a prop */
      /* eslint-disable-next-line marty/form-has-method */}
      <form onSubmit={onSubmit} method={method} action={action} {...formListeners}>
        <fieldset disabled={parentIsDisabled}>
          {controls.map((option, index) => (
            <FormElement {...option} key={option.label + option.name} index={index} />
          ))}
        </fieldset>
      </form>
    </div>
  );
};

export default withErrorBoundary('ZapposForm', ZapposForm);
