import { useState } from 'react';
import { gtag, install } from 'ga-gtag';

import type { ZAWTask } from './ZAWTaskList';
import ZAWTaskList from './ZAWTaskList';
import type { Callout } from './ZAWTextCallout';
import ZAWTextCallout from './ZAWTextCallout';

import { guid } from 'helpers/guid';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { formInputsToObject } from 'helpers/HtmlHelpers';
import { AriaLiveTee } from 'components/common/AriaLive';
import { trackEvent } from 'helpers/analytics';
import useMartyContext from 'hooks/useMartyContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { postZAWSubmit } from 'apis/zaw';
import { FORM_MESSAGES } from 'constants/zaw';
import { appendUTMs } from 'helpers/zaw';
import { QuickShield } from 'components/icons';

import css from 'styles/components/landing/zaw/zawFormStandalone.scss';

const contentSymphonyDefaults = {
  heading: 'Get in Touch with a Zappos at Work Team Member',
  calloutsHeadings: 'Zappos at Work Benefits',
  callouts: [
    {
      icon: 'circleSuccess',
      body: 'Easy-to-use online storefronts, built custom to your business'
    },
    {
      icon: 'circleSuccess',
      body: 'Over 3,000+ styles from top-rated brands.'
    },
    {
      icon: 'circleSuccess',
      body: 'Free & fast shipping, returns, and exchanges'
    },
    {
      icon: 'circleSuccess',
      body: '24/7 customer service for any order troubles'
    }
  ],
  tasks: [
    {
      name: 'Complete and submit the contact form'
    },
    {
      name: 'A team member will reach out to you within one business day'
    },
    {
      name: "Meet with us to discuss your team's workwear needs and ask any questions"
    }
  ],
  quickShieldMessaging: 'Learn about our QuickShield solution for Enterprise clients!',
  terms: [
    {
      company: 'spotify',
      text: 'A consultation with a Zappos at Work team member must be scheduled on or before 12/26/2023 to be eligible for this promotion. Upon completion of the consultation, a Code will be emailed to the email address provided during the consultation within 2 business days. An active Zappos.com account is required to redeem the Code. The Code is subject to the terms included in the email and is valid for one pair of select safety shoes sold on the Zappos.com website or app, up to a retail price of $150.00 USD. This promotion is subject to change or cancellation without notice and other restrictions may apply.'
    }
  ]
};

interface IZAWFormStandaloneUtm {
  utm_source?: string;
  utm_channel?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

type Term = {
  company: string;
  text: string;
};

type IZAWFormStandaloneCompanySize = string | undefined;

type IZAWFormStandaloneStatus = 'amazon' | 'error' | 'success';
export interface IZAWFormStandaloneProps {
  slotName: string;
  slotIndex: number;
  slotDetails: {
    amazonErrorMessage?: string;
    callouts: Callout[];
    calloutsHeading: string;
    failureMessage?: string;
    heading?: string;
    quickShieldMessaging?: string;
    redirectUrl: string;
    tasks: ZAWTask[];
    terms?: Term[];
    legalMessaging?: string;
  };
}

export const ZAWFormStandalone = (props: IZAWFormStandaloneProps) => {
  useEffectOnce(() => {
    install(id);
    const urlParams = new URLSearchParams(window.location.search);
    const utmNames: (keyof IZAWFormStandaloneUtm)[] = ['utm_source', 'utm_channel', 'utm_campaign', 'utm_term', 'utm_content'];
    // set utms to pass on submit
    for (const utm of utmNames) {
      if (urlParams && urlParams.has(utm)) {
        const utmValue = urlParams.get(utm);
        updateUtm(prevUtm => ({
          ...prevUtm,
          [utm]: utmValue
        }));
      }
    }

    // set terms if theres an active company
    if (terms) {
      for (const term of terms) {
        if (urlParams?.toString().includes(term.company)) {
          setActiveTerm(term.text);
          // note: doesn't support multiple terms,
          // if two companies are listed in utms with matching terms,
          // first company terms are used.
          return;
        }
      }
    }
  });

  const { slotName, slotIndex, slotDetails } = props;
  // These slot details are specifically set to match the CMS keys
  const {
    amazonErrorMessage = FORM_MESSAGES.amazon,
    callouts = contentSymphonyDefaults.callouts,
    calloutsHeading = contentSymphonyDefaults.calloutsHeadings,
    failureMessage = FORM_MESSAGES.error,
    heading = contentSymphonyDefaults.heading,
    quickShieldMessaging = contentSymphonyDefaults.quickShieldMessaging,
    redirectUrl,
    tasks = contentSymphonyDefaults.tasks,
    terms = contentSymphonyDefaults.terms,
    legalMessaging = 'By filling out this form, you agree to receive occasional marketing emails from Zappos at Work about their products, services, events, webinars, or other invitations. You can opt out of these emails at any time by clicking the unsubscribe link at the bottom of each email. Zappos at Work is the provider of this website and respects your privacy rights. To learn more, please read our privacy policy.'
  } = slotDetails;

  const {
    testId,
    marketplace: {
      zaw: {
        google: { id, conversionId }
      }
    }
  } = useMartyContext();

  const contentId = `zaw-form-slot-${slotIndex}`;
  const [formStatus, setFormStatus] = useState<IZAWFormStandaloneStatus | undefined>(undefined);
  const [selected, setSelected] = useState<IZAWFormStandaloneCompanySize>(undefined);
  const [utm, updateUtm] = useState<IZAWFormStandaloneUtm | {}>({});
  const [activeTerm, setActiveTerm] = useState<string | undefined>(undefined);

  const onSubmit = (e: any) => {
    e.preventDefault();

    const companyName = e.target?.elements['company'].value;
    if (companyName?.toLowerCase().includes('amazon')) {
      setFormStatus('amazon');
      return;
    }

    const guidId = guid();
    const { company, email, name, phone, companySize } = formInputsToObject(e.target);
    const redirectUrlWithUtms = appendUTMs(redirectUrl, new URLSearchParams(window.location.search).toString());
    const formData = {
      company,
      companySize,
      email,
      guid: guidId,
      message: '- sent with LP Standalone', // TODO remove from customer portal https://code.amazon.com/packages/ZapposAtWorkCustomerPortal/blobs/1df9b556bde7be8208a63bf4e600c16629400610/--/controllers/home.js#L488-L492 then delete
      name,
      phone,
      ...utm
    };

    trackEvent('ZAW_GUID', guidId);
    trackEvent('ZAW_STANDALONE_FORM_SUBMIT');

    gtag('event', 'conversion', {
      send_to: conversionId
    });

    postZAWSubmit(formData, setFormStatus, redirectUrlWithUtms);
  };

  return (
    <>
      <article data-slot-id={slotName} data-slotindex={slotIndex}>
        <div className={css.bg}>
          <div className={css.content}>
            <h1 className={css.heading}>{heading}</h1>
            <form data-test-id={testId('zaw-form')} className={css.interestFormContainer} onSubmit={onSubmit} id={contentId} method="post">
              {formStatus === 'error' && (
                <AriaLiveTee>
                  <p className={css.error}>{failureMessage}</p>
                </AriaLiveTee>
              )}
              {formStatus === 'amazon' && (
                <AriaLiveTee>
                  <p className={css.amazon}>{amazonErrorMessage}</p>
                </AriaLiveTee>
              )}

              <div className={css.fields}>
                <fieldset>
                  <li className={css.inputWrapper}>
                    <label className={css.inputWrapperLabel} htmlFor="name">
                      Name
                    </label>
                    <input className={css.inputWrapperInput} type="text" name="name" id="name" placeholder="Jane Smith" required />
                  </li>
                  <li className={css.inputWrapper}>
                    <label className={css.inputWrapperLabel} htmlFor="company">
                      Company
                    </label>
                    <input className={css.inputWrapperInput} type="text" name="company" id="company" placeholder="Where you work!" required />
                  </li>
                  <li className={css.toggleWrapper}>
                    <legend className={css.toggleLabel}>How many employees are eligible for your safety workwear or footwear program?</legend>
                    <div className={css.toggleRadioWrapper}>
                      <input
                        className={css.toggleRadioInput}
                        name="companySize"
                        id="lessThan200"
                        type="radio"
                        value={selected === 'lessThan200' ? selected : undefined}
                        onChange={() => setSelected('lessThan200')}
                        required
                      />
                      <label htmlFor="lessThan200" className={css.toggleRadio + (selected === 'lessThan200' ? ' ' + css.toggleRadioActive : '')}>
                        Less than 200
                      </label>
                      <input
                        className={css.toggleRadioInput}
                        id="moreThan200"
                        name="companySize"
                        type="radio"
                        value={selected === 'moreThan200' ? selected : undefined}
                        onChange={() => setSelected('moreThan200')}
                        required
                      />
                      <label htmlFor="moreThan200" className={css.toggleRadio + (selected === 'moreThan200' ? ' ' + css.toggleRadioActive : '')}>
                        More than 200
                      </label>
                    </div>
                    <div className={css.quickShield + (selected === 'moreThan200' ? ' ' + css.quickShieldActive : '')}>
                      <div className={css.quickShieldIcon}>
                        <QuickShield />
                      </div>
                      {quickShieldMessaging}
                    </div>
                  </li>
                  <li className={css.inputWrapper}>
                    <label className={css.inputWrapperLabel} htmlFor="email">
                      Email
                    </label>
                    <input className={css.inputWrapperInput} type="email" name="email" id="email" placeholder="janesmith@example.com" required />
                  </li>

                  <li className={css.inputWrapper}>
                    <label className={css.inputWrapperLabel} htmlFor="phone">
                      Phone Number
                    </label>
                    <input className={css.inputWrapperInput} type="tel" name="phone" id="phone" placeholder="123 - 456 - 7891" required />
                  </li>
                </fieldset>
                <button className={css.submitButton} type="submit">
                  Submit
                </button>
                <p className={css.legalMessaging}>{legalMessaging}</p>
              </div>
            </form>
            <div className={css.tasks}>
              <ZAWTaskList slotDetails={{ tasks }} />
            </div>
          </div>
          {activeTerm && <div className={css.terms}>{`*${activeTerm}`}</div>}
        </div>
        <ZAWTextCallout slotDetails={{ heading: calloutsHeading, callouts, isReducedStyling: true }} />
      </article>
    </>
  );
};

export default withErrorBoundary('ZAWFormStandalone', ZAWFormStandalone);
