// import SingleReview from 'components/common/SingleReview';
import React, { useState } from 'react';

import { cn } from 'helpers/classnames';
import leftQuotation from 'images/zaw/quote_left.svg';
import rightQuotation from 'images/zaw/quote_right.svg';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/zaw/zawTestimonials.scss';

interface IZAWTestimonial {
  title: string;
  testimonialQuote: string;
}
export interface IZAWTestimonialsProps {
  slotDetails: {
    showArrows?: boolean;
    testimonials: IZAWTestimonial[];
  };
}

export const MOCK_TESTIMONIALS = [
  {
    title: 'Zappos at Work Fortune500 B2B Client',
    testimonialQuote:
      '“Zappos at Work has allowed our company to save money, has saved our teams time, and continues to provide our employees with first class, memorable experiences”'
  },
  {
    title: 'Tomater',
    testimonialQuote:
      '“Zappos at Work has allowed our company to save money, has saved our teams time, and continues to provide our employees with first class, memorable experiences”'
  }
];

export const ZAWTestimonials = (props: IZAWTestimonialsProps) => {
  const {
    slotDetails: { testimonials = MOCK_TESTIMONIALS, showArrows = true }
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const pageCount = testimonials.length;

  const makeDots = () => {
    if (pageCount <= 1) {
      return;
    }
    return (
      <div className={css.paging}>
        {Array(pageCount)
          .fill(undefined)
          .map((_, index) => (
            <button
              type="button"
              key={index}
              className={cn({ [css.active]: index === currentIndex })}
              onClick={() => setCurrentIndex(index)}
              tabIndex={0}
            >
              {index + 1}
            </button>
          ))}
      </div>
    );
  };

  const makeArrows = () => {
    if (pageCount <= 1) {
      return;
    }

    return [
      <button
        type="button"
        key="prev"
        className={cn(css.prev, {
          [css.inactive]: currentIndex === 0
        })}
        onClick={() => {
          setCurrentIndex(Math.max(0, Math.min(currentIndex - 1, pageCount - 1)));
        }}
      >
        Previous
      </button>,
      <button
        type="button"
        key="next"
        className={cn(css.next, {
          [css.inactive]: currentIndex >= pageCount - 1
        })}
        onClick={() => {
          setCurrentIndex(Math.max(0, Math.min(currentIndex + 1, pageCount - 1)));
        }}
      >
        Next
      </button>
    ];
  };

  return (
    <div className={css.container}>
      <img className={css.leftQuotation} alt="left Quote" src={leftQuotation} />
      <h2 className={css.heading}>Customer Testimonials</h2>
      {testimonials.map((testimonial, index) => {
        if (index !== currentIndex) {
          return;
        }

        return (
          <article className={css.quoteContainer} key={`testimonial-${index}`}>
            <blockquote className={css.quoteText}>{testimonial.testimonialQuote}</blockquote>
            <p className={css.titleText}>{testimonial.title}</p>
          </article>
        );
      })}
      {showArrows && makeArrows()}
      {makeDots()}
      <img className={css.rightQuotation} alt="right Quote" src={rightQuotation} />
    </div>
  );
};

export default withErrorBoundary('ZAWTestimonials', ZAWTestimonials);
