import React from 'react';

import sampleShoe1 from 'images/zaw/sample-shoe1.png';
import sampleShoe2 from 'images/zaw/sample-shoe2.png';
import { Accordion, AccordionItem } from 'components/common/MelodyAccordion';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import MartyLink from 'components/common/MartyLink';

import css from 'styles/components/landing/zaw/zawAccordion.scss';

export const MOCK_DATA_SECTIONS = [
  {
    heading: 'Warehousing',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis malesuada viverra quis. Lorem ipsum dolor sit amet,  adipiscing elit.',
    link: 'zappos.com',
    linkName: 'Browse',
    itemOneImage: sampleShoe1,
    itemOneName: 'Shoe 1',
    itemTwoImage: sampleShoe2,
    itemTwoName: 'Shoe 2'
  },
  {
    heading: 'Healthcare',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis malesuada viverra quis. Lorem ipsum dolor sit amet,  adipiscing elit.',
    link: 'zappos.com',
    linkName: 'Browse',
    itemOneImage: sampleShoe1,
    itemOneName: 'Shoe 1',
    itemTwoImage: sampleShoe2,
    itemTwoName: 'Shoe 2'
  },
  {
    heading: 'Engineering',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis malesuada viverra quis. Lorem ipsum dolor sit amet,  adipiscing elit.',
    link: 'zappos.com',
    linkName: 'Browse',
    itemOneImage: sampleShoe1,
    itemOneName: 'Shoe 1',
    itemTwoImage: sampleShoe2,
    itemTwoName: 'Shoe 2'
  }
];

export interface IZAWAccordionSection {
  heading: string;
  body: string;
  link: string;
  linkName: string;
  itemOneImage: string;
  itemOneName: string;
  itemTwoImage: string;
  itemTwoName: string;
}

export interface IZAWAccordionProps {
  slotDetails: {
    heading: string;
    sections: IZAWAccordionSection[];
  };
}

export const ZAWAccordion = (props: IZAWAccordionProps) => {
  const {
    slotDetails: { sections = MOCK_DATA_SECTIONS, heading = 'Top Industries' }
  } = props;
  const backgroundColorStyle = { backgroundColor: '#EBF4FF' };
  const openBackgroundColorStyle = { backgroundColor: '#fff' };
  return (
    <div className={css.accordionContainer}>
      {!!heading && <h2 className={css.heading}>{heading}</h2>}
      <Accordion defaultOpenIndex={0}>
        {sections.map(({ heading, body, link, linkName, itemOneImage, itemOneName, itemTwoImage, itemTwoName }: IZAWAccordionSection, index) => (
          <AccordionItem
            heading={heading}
            backgroundColorStyle={backgroundColorStyle}
            openBackgroundColorStyleOverride={openBackgroundColorStyle}
            headingAs="h2"
            key={index}
          >
            <div className={css.accordionItemContainer}>
              <div className={css.itemInformation}>
                <p>{body}</p>
                <MartyLink className={css.browseButton} to={link}>
                  {linkName}
                </MartyLink>
              </div>

              <div className={css.productCallout}>
                <img className={css.productCalloutImage} src={itemOneImage} alt={itemOneName} />
                <p className={css.productCalloutText}>{itemOneName}</p>
              </div>

              <div className={css.productCallout}>
                <img className={css.productCalloutImage} src={itemTwoImage} alt={itemTwoName} />
                <p className={css.productCalloutText}>{itemTwoName}</p>
              </div>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default withErrorBoundary('ZAWAccordion', ZAWAccordion);
