/* eslint-disable camelcase */

import type { FormEvent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Link from 'components/hf/HFLink';
import type { SurveyData, SurveyFormData, SurveyQuestion } from 'components/survey/SurveyForm.types';
import type SurveyFormProps from 'components/survey/SurveyForm.types';
import { SmallLoader } from 'components/Loader';
import timedFetch from 'middleware/timedFetch';
import { getSurveyQuestions, saveMultiSurveyQuestionResponse } from 'apis/mafia';
import { getMafiaAndCredentials } from 'store/ducks/readFromStore';
import css from 'components/survey/SurveyForm.scss';

const SurveyForm = (props: SurveyFormProps) => {
  const {
    slotDetails: { surveyName, formHeading, submittedMsg, isCustomer }
  } = props;
  const { mafiaConfig, credentials } = useSelector(getMafiaAndCredentials);
  const [surveyData, setSurveyData] = useState<SurveyData>();
  const [formData, updateFormData] = useState<SurveyFormData>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { csrf_token, survey_id, questions } = surveyData || {};

  useEffect(() => {
    const abortController = new AbortController();
    const fetchSurveyQuestions = async () => {
      const data = await getSurveyQuestions(mafiaConfig, surveyName, timedFetch('getSurveyQuestions'), abortController);
      setSurveyData(data);
    };

    fetchSurveyQuestions();

    return () => abortController.abort();
  }, []);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value.trim()
    });
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const saveSurvey = async () => {
      const response = await saveMultiSurveyQuestionResponse(
        mafiaConfig,
        { responses: formData, csrfToken: csrf_token, surveyId: survey_id },
        credentials
      );

      const { success } = response || {};
      setIsSubmitted(success);
    };
    saveSurvey();
  };

  if (!isCustomer) {
    return (
      <h2 className={css.signIn}>
        <Link to="/login" aria-label="Sign In">
          Sign In / Register to enter
        </Link>
      </h2>
    );
  }

  if (!surveyData || !questions) {
    return <SmallLoader />;
  }

  if (isSubmitted) {
    return <h1 className={css.submitMsg}>{submittedMsg}</h1>;
  }

  return (
    <form className={css.form} method="post" onSubmit={onFormSubmit}>
      <h1 className={css.formHeading}>{formHeading}</h1>
      {questions?.map(({ content, id }: SurveyQuestion) => (
        <Fragment key={id}>
          <label htmlFor={String(id)}>{content}</label>
          <input
            id={String(id)}
            className={css.input}
            name={String(id)}
            type={content.toLowerCase() === 'email address' ? 'email' : 'text'}
            required
            onChange={handleChange}
          ></input>
        </Fragment>
      ))}
      <button className={css.submitBtn} type="submit">
        Submit
      </button>
    </form>
  );
};

export default SurveyForm;
