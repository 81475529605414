import { connect } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { trackEvent } from 'helpers/analytics';
import MelodyCardCategory from 'components/common/melodyCard/MelodyCardCategory';
import { evCategoryStreamClick, evCategoryStreamImpression } from 'events/symphony';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { track } from 'apis/amethyst';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/landing/melodyPersonalizedCategories.scss';

export const MelodyPersonalizedCategories = ({ firstName, slotName, slotDetails, slotIndex, onComponentClick, shouldLazyLoad, slideWidths }) => {
  const { categories } = slotDetails;

  useEffect(() => {
    trackEvent('TE_PERSONALIZED_CATEGORIES_VIEW', `slotIndex:${slotIndex}`);
    (slotDetails.categories || []).forEach(category => {
      track(() => [evCategoryStreamImpression, { slotDetails: { ...slotDetails, ...category }, slotName, slotIndex, personalized: true }]);
    });
  }, [slotDetails, slotIndex, slotName]);

  const handleClick = useCallback(
    (e, card) => {
      trackEvent('TE_PERSONALIZED_CATEGORIES_CLICK', `slotIndex:${slotIndex}`);
      onComponentClick && onComponentClick(e);
      track(() => [
        evCategoryStreamClick,
        {
          slotDetails: { ...slotDetails, ...card },
          identifier: card?.name,
          slotIndex,
          slotName,
          personalized: true
        }
      ]);
    },
    [onComponentClick, slotDetails, slotIndex, slotName]
  );

  if (categories?.length) {
    return (
      <div className={css.carousel} data-slot-id={slotName}>
        <Carousel
          slideWidths={slideWidths}
          slides={categories.map((category, index) => (
            <MelodyCardCategory
              cardData={category}
              onComponentClick={handleClick}
              key={index}
              eventLabel="melodyPersonalizedCategories"
              melodyCardTestId="melodyPersonalizedCategories"
              shouldLazyLoad={shouldLazyLoad}
            />
          ))}
          header={firstName && { title: `${firstName}, You Might Like These Categories.` }}
        />
      </div>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  return {
    firstName: state.holmes?.firstName
  };
}

const ConnectedMelodyPersonalizedCategories = connect(mapStateToProps)(MelodyPersonalizedCategories);
export default withErrorBoundary('ConnectedMelodyPersonalizedCategories', ConnectedMelodyPersonalizedCategories);
