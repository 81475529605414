import type { VideoPlayerWrapperProps } from 'components/common/VideoPlayer/VideoPlayerWrapper.types';
import VideoPlayer from 'components/common/VideoPlayer/VideoPlayer';
import useMartyContext from 'hooks/useMartyContext';
import { isValidMartyPath } from 'helpers/LocationUtils';

const VideoPlayerWrapper = (props: VideoPlayerWrapperProps) => {
  const {
    slotName,
    slotIndex,
    slotDetails: {
      poster,
      src,
      variant = 'outlinedReversed',
      controls = false,
      muted = true,
      autoPlay = true,
      loop = true,
      playsInline = true,
      content: { heading, copy, cta, url, placement, backgroundColor } = {}
    } = {}
  } = props;

  const { router } = useMartyContext();
  const shouldShowVideo = src && poster;

  if (!shouldShowVideo) {
    return null;
  }

  const handleCTAAction = () => {
    router.pushPreserveAppRoot(isValidMartyPath(url ?? '') ? url : '/');
  };

  return (
    <div className="mb-4 sm:mb-8 md:mb-16" data-slot-id={slotName} data-slotindex={slotIndex}>
      <VideoPlayer
        src={src}
        poster={poster}
        variant={variant}
        heading={heading}
        copy={copy}
        cta={cta}
        url={url}
        backgroundColor={backgroundColor}
        placement={placement}
        handleCTAAction={handleCTAAction}
        controls={Boolean(controls)}
        muted={Boolean(muted)}
        autoPlay={Boolean(autoPlay)}
        loop={Boolean(loop)}
        playsInline={Boolean(playsInline)}
      />
    </div>
  );
};

export default VideoPlayerWrapper;
