import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import type { BannerAdProps } from './BannerAd.types';
import { containerClasses, imageClasses, imageWrapperClasses, sponsoredAfterClasses } from './BannerAd.classes';

import Image from 'components/common/Image';
import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { useSponsoredAdsBannerAds } from 'hooks/useSponsoredAdsBannerAds';
import { selectIsFeatureBannerAds } from 'selectors/features';
import useTrackSponsoredAdsBannerAds from 'hooks/useTrackSponsoredAdsBannerAds';
import { trackClickEvent } from 'utils/sponsoredAds/sponsoredAdsBannerAdsUtil';
import useSponsoredAdsBannerAdsClickEvent from 'hooks/useSponsoredAdsBannerAdsClickEvent';
import { INVIEW_THRESHOLD } from 'constants/sponsoredAdsBannerAds';

export const BannerAdInTest = (bannerAdProps: BannerAdProps) => {
  const isShowBannerAdsFeature = useSelector(selectIsFeatureBannerAds);
  if (!isShowBannerAdsFeature) {
    return null;
  }
  return <BannerAd {...bannerAdProps} />;
};

export const BannerAd = ({ 'data-test-id': dataTestId, position = 'bottom', className, slotIndex, ...forwardProps }: BannerAdProps) => {
  const { testId } = useMartyContext();
  const ads = useSponsoredAdsBannerAds({ position });
  const { bannerAds } = ads ?? {};
  const { assetUrl, clickThru = '', viewTracker, clickTracker, impressionTracker, brand } = bannerAds?.[0] ?? {};
  const hasAsset = Boolean(assetUrl?.length);
  const sponsoredClasses = assetUrl ? sponsoredAfterClasses : '';

  const [intersectionRef, inView] = useInView({
    threshold: INVIEW_THRESHOLD,
    triggerOnce: true
  });

  useTrackSponsoredAdsBannerAds({ viewTracker, impressionTracker, inView });
  const eventData = useSponsoredAdsBannerAdsClickEvent({ brand: brand ?? undefined, slot: slotIndex });

  // CLS ( on search top slot ), agreed as the go forward solution instead of using a placeholder fallback image.
  if (!hasAsset) {
    return null;
  }

  return (
    <div className={cn(containerClasses, sponsoredClasses, className)} data-test-id={testId(dataTestId)} {...forwardProps}>
      <div className={imageWrapperClasses}>
        <a href={clickThru} onClick={() => trackClickEvent(eventData, clickTracker)} aria-label="sponsored banner ad" ref={intersectionRef}>
          <Image className={imageClasses} src={assetUrl} alt="bannerAd" />
        </a>
      </div>
    </div>
  );
};
