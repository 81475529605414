import { forwardRef } from 'react';
import { type ButtonVariant } from '@mweb/zappos-ui/Button';
import {
  VideoPlayer as DesignLibraryVideoPlayer,
  VideoPlayerContent,
  VideoPlayerLayout,
  type VideoPlayerLayoutPlacement,
  VideoPlayerOverlay,
  type VideoPlayerOverlayPlacement
} from '@mweb/zappos-ui/VideoPlayer';

import Button from 'components/common/Button';
import type { VideoPlayerProps } from 'components/common/VideoPlayer/VideoPlayer.types';

const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>((props: VideoPlayerProps, ref) => {
  const {
    variant = 'outlinedReversed',
    heading,
    copy,
    cta,
    url,
    placement = 'center',
    handleCTAAction,
    controls = false,
    muted = true,
    autoPlay = true,
    loop = true,
    playsInline = true,
    backgroundColor,
    ...forwardProps
  } = props;

  const hasContent = heading || copy || cta;
  const commonVideoProps = { autoPlay, muted, loop, controls, playsInline, ...forwardProps, ref };

  if (!hasContent) {
    return <DesignLibraryVideoPlayer {...commonVideoProps} />;
  }

  const hasCTA = cta && url ? true : false;
  const linkCTA: ButtonVariant[] = ['text', 'textReversed'];
  const action = linkCTA.includes(variant) ? { href: url } : { onClick: () => handleCTAAction?.() };
  const overlayPlacements: VideoPlayerOverlayPlacement[] = ['center', 'center-right', 'bottom-right'];
  const layoutPlacements: VideoPlayerLayoutPlacement[] = ['content-left', 'content-right', 'content-bottom'];
  const contentOverlayPlacement = overlayPlacements.includes(placement as VideoPlayerOverlayPlacement) ? placement : undefined;
  const contentLayoutPlacement = layoutPlacements.includes(placement as VideoPlayerLayoutPlacement) ? placement : undefined;
  const commonContentProps = { variant, heading, subHeading: copy, cta: hasCTA ? <Button {...action}>{cta}</Button> : undefined };

  if (contentOverlayPlacement) {
    return (
      <DesignLibraryVideoPlayer {...commonVideoProps}>
        <VideoPlayerOverlay placement={contentOverlayPlacement as VideoPlayerOverlayPlacement}>
          <VideoPlayerContent {...commonContentProps} />
        </VideoPlayerOverlay>
      </DesignLibraryVideoPlayer>
    );
  }

  if (contentLayoutPlacement) {
    return (
      <VideoPlayerLayout
        contentBackground={backgroundColor}
        contentVariant={variant}
        placement={placement as VideoPlayerLayoutPlacement}
        videoPlayer={<DesignLibraryVideoPlayer {...commonVideoProps} />}
        videoPlayerContent={<VideoPlayerContent {...commonContentProps} />}
      />
    );
  }

  return <DesignLibraryVideoPlayer {...commonVideoProps} />;
});

export default VideoPlayer;
