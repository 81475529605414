import { Component } from 'react';
import { connect } from 'react-redux';

import { MartyContext } from 'utils/context';
import { evRecommendationClick } from 'events/recommendations';
import { track } from 'apis/amethyst';
import VipOnlyLogo from 'components/icons/vipDashboard/VipOnlyLogo';
import MelodyCardCategory from 'components/common/melodyCard/MelodyCardCategory';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/landing/melodyPersonalizedBrand.scss';

const widgetType = 'BRAND_RECOMMENDATION_WIDGET';
const recommendationSource = 'ZAPPOS_DATA_SCIENCE';
const recommendationType = 'BRAND_RECOMMENDATION';

export class MelodyPersonalizedBrand extends Component {
  handleClick = (e, index, id) => {
    const { onComponentClick } = this.props;
    onComponentClick(e);
    track(() => [
      evRecommendationClick,
      {
        index,
        recommendationType,
        recommendationValue: id,
        recommendationSource,
        widgetType
      }
    ]);
  };

  makeHeadingText = isTrending => {
    const { firstName } = this.props;
    if (isTrending) {
      return 'Trending Brands';
    }
    return firstName ? `${firstName}, You Might Like These Brands` : 'Brands For You';
  };

  makeBrandPromo = () => (
    <p className={css.brandPromo}>
      <VipOnlyLogo />
      Bonus Points
    </p>
  );

  render() {
    return (
      <MartyContext.Consumer>
        {({ marketplace }) => {
          const {
            slotName,
            slotDetails: { brands },
            shouldLazyLoad,
            slideWidths
          } = this.props;
          const { desktopBaseUrl, hasRewardsTransparency } = marketplace;

          if (brands && brands.length) {
            const recoSource = brands[0].recommendationSource;
            const isTrending = recoSource && recoSource === 'TRENDING_BRANDS';

            return (
              <div className={css.carousel} data-slot-id={slotName}>
                <Carousel
                  slideWidths={slideWidths}
                  slides={brands.map((brand, index) => {
                    const { headerImageUrl, name, cleanName, id, brandUrl, brandPromo } = brand;
                    const link = brandUrl || `/search/null/filter/brandNameFacet/${encodeURIComponent(name)}`;
                    const cardData = {
                      image: `${desktopBaseUrl}${headerImageUrl}`,
                      name,
                      alt: name,
                      gae: cleanName,
                      link
                    };

                    return (
                      <MelodyCardCategory
                        cardData={cardData}
                        eventLabel="melodyPersonalizedBrand"
                        melodyCardTestId="melodyPersonalizedBrand"
                        onComponentClick={e => {
                          this.handleClick(e, index, id);
                        }}
                        shouldLazyLoad={shouldLazyLoad}
                        key={id}
                      >
                        {brandPromo && hasRewardsTransparency && this.makeBrandPromo()}
                      </MelodyCardCategory>
                    );
                  })}
                  header={{ title: this.makeHeadingText(isTrending) }}
                />
              </div>
            );
          }
          return false;
        }}
      </MartyContext.Consumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.holmes.firstName
  };
}

const ConnectedMelodyPersonalizedBrand = connect(mapStateToProps, {})(MelodyPersonalizedBrand);
export default withErrorBoundary('MelodyPersonalizedBrand', ConnectedMelodyPersonalizedBrand);
