import type { Dispatch } from 'react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { AnyAction } from 'redux';
import { weakMapMemoize } from 'reselect';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import ProductCard from 'components/common/ProductCard';
import { track } from 'apis/amethyst';
import { evProductInteract } from 'events/search';
import useFetchProductRelation from 'hooks/useFetchProductRelation';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import { evHeroWithProductStreamClick, evSearchProductStreamClick, evSearchProductStreamImpression } from 'events/symphony';
import { formattedProductsWithBadges, getRowHasBadge, getRowHasBadgeClassname } from 'helpers/BadgeUtils';
import type { SlotDetails } from 'types/landing';
import { setHFSearchTerm, updateOriginalTerm } from 'actions/headerfooter';
import Carousel from 'components/common/Carousel/Carousel';
import type { SlideWidths } from 'components/common/Carousel/Carousel.types';
import type { HeartProps } from 'types/hearts';
import type { ProductWithRelations, ProductWithRelationsFromCalypso } from 'types/calypso';
import type { Product } from 'constants/searchTypes';

import css from 'styles/components/landing/landingProductCardWrapper.scss';

interface LandingProductProps {
  slotDetails: SlotDetails;
  slotIndex?: number;
  slotName?: string;
  slotHeartsData: HeartProps;
  shouldLazyLoad: boolean;
  isFullWidth?: boolean;
  componentStyling?: string;
  isCarousel?: boolean;
  maxDisplay?: number;
  slideWidths?: SlideWidths;
}
const onProductMediaHovered = (product: Product | ProductWithRelations, styleId: string) => {
  track(() => [
    evProductInteract,
    {
      mainStyleId: styleId,
      interactedProduct: product,
      interactionType: 'HOVER'
    }
  ]);
};

const msaImageParams = {
  autoCrop: true
} as const;

const productCardRelatedStyles = weakMapMemoize((productRelations: { [p: string]: ProductWithRelationsFromCalypso } | undefined, styleId: any) => {
  if (!productRelations) {
    return {} as ProductWithRelations;
  }
  return getProductWithRelatedStyles(styleId, productRelations);
});

const productCardOnProductClick = weakMapMemoize(
  (
    slotDetails: SlotDetails,
    slotIndex: number | undefined,
    slotName: string | undefined,
    productWithRelatedStyles: any,
    heroCount: number,
    dispatch: Dispatch<AnyAction>
  ) =>
    () => {
      const filterCondition = slotDetails.style === 'default-results' || slotDetails.style === 'melody';

      if (!filterCondition) {
        return track(() => [
          evHeroWithProductStreamClick,
          {
            slotDetails,
            slotIndex,
            slotName,
            productWithRelatedStyles,
            heroCount
          }
        ]);
      }

      track(() => [evSearchProductStreamClick, { slotDetails, slotIndex, slotName, productWithRelatedStyles }]);

      dispatch(setHFSearchTerm(''));
      dispatch(updateOriginalTerm());
    }
);

export const LandingProductCardWrapper = (props: LandingProductProps) => {
  const { slotDetails, slotIndex, slotName, shouldLazyLoad, slotHeartsData, isFullWidth, componentStyling, isCarousel, maxDisplay, slideWidths } =
    props;
  const { style, image, products, eventLabel: eventLabelCustom, componentName, isCrossSiteSearch, siteName, title } = slotDetails || {};
  const { testId } = useMartyContext();
  const heroCount = image ? 1 : 0;
  const eventLabel = eventLabelCustom || style || componentName;
  const commonProps = { ...props, eventLabel, shouldLazyLoad };
  const styleIds = products.map(product => product.styleId);

  const [productRelations] = useFetchProductRelation(styleIds);

  const formattedBadgeProducts = formattedProductsWithBadges(products, productRelations);

  const dispatch = useDispatch();

  let commonPropsWithBadges: {
    eventLabel: string | undefined;
    shouldLazyLoad: boolean;
    slotDetails: SlotDetails;
    slotIndex?: number;
    slotName?: string | undefined;
    slotHeartsData: HeartProps;
    isFullWidth?: boolean | undefined;
    componentStyling?: string | undefined;
    isCarousel?: boolean | undefined;
    maxDisplay?: number | undefined;
    formattedBadgeProducts: { productId: string | undefined; styleId: string; colorId: any; badgeId: string | undefined }[]; // TODO ts colorId needs to be looked at. Some places string, others it is a number.
  };

  if (formattedBadgeProducts.length && style === 'melody') {
    commonPropsWithBadges = { formattedBadgeProducts, ...commonProps };
  }

  useEffect(() => {
    if (isCarousel) {
      track(() => [evSearchProductStreamImpression, commonPropsWithBadges]);
    }
  }, [formattedBadgeProducts.length]);

  if (!products?.length) {
    return null;
  }

  const rowHasBadge = getRowHasBadge(products, productRelations);

  const rowBadgeClassname = getRowHasBadgeClassname(style);

  const productCards = [];
  for (const [index, product] of products.entries()) {
    const { styleId } = product;
    const productWithRelatedStyles = productCardRelatedStyles(productRelations, styleId);
    const { relatedStyles, badges, ...productInfo } = productWithRelatedStyles;

    const onProductClick = productCardOnProductClick(slotDetails, slotIndex, slotName, productWithRelatedStyles, heroCount, dispatch);

    if (relatedStyles?.length) {
      if (maxDisplay) {
        if (index > maxDisplay) {
          return;
        }
      }

      productCards.push(
        <ColorSwatchWrapper
          {...productInfo}
          className={cn(componentStyling, { [css.card]: !isCarousel })}
          relatedStyles={relatedStyles}
          heartsInfo={slotHeartsData}
          key={styleId + eventLabel}
          eventLabel={eventLabel}
          msaImageParams={msaImageParams}
          onProductMediaHovered={onProductMediaHovered}
          onClick={onProductClick}
          testId={testId(eventLabel)}
          isCrossSiteSearch={isCrossSiteSearch}
          siteName={siteName}
          imageBadgeClassName={(rowHasBadge && rowBadgeClassname) || undefined}
          badges={badges}
        />
      );
      continue;
    }

    const productCardOtherProps = { ...product, productType: productInfo.productType, txAttrFacet_Gender: productInfo.txAttrFacet_Gender };

    productCards.push(
      <ProductCard
        key={styleId + eventLabel}
        index={index}
        eventLabel={eventLabel}
        msaImageParams={msaImageParams}
        {...productCardOtherProps}
        className={cn(componentStyling, { [css.card]: !isCarousel })}
        testId={testId(eventLabel)}
        onClick={onProductClick}
        hearts={slotHeartsData}
        isCrossSiteSearch={isCrossSiteSearch}
        siteName={siteName}
        isFullWidth={isFullWidth}
        imageBadgeClassName={rowHasBadge && rowBadgeClassname}
        badges={badges}
      />
    );
  }

  if (isCarousel) {
    return (
      <div className={css.carousel}>
        <Carousel slideWidths={slideWidths} slides={productCards as JSX.Element[]} header={{ title: title }} />
      </div>
    );
  }

  return <>{productCards}</>;
};

export default LandingProductCardWrapper;
