import React from 'react';

import { cn } from 'helpers/classnames';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import DollarSlashIcon from 'images/zaw/dollar-slash.svg';
import CheckmarkIcon from 'images/zaw/checkmark.svg';
import ChecklistIcon from 'images/zaw/checklist.svg';
import ShippingCarIcon from 'images/zaw/shipping-car.svg';
import Organization from 'images/zaw/organization.svg';
import Tag from 'images/zaw/tag.svg';
import Cart from 'images/zaw/cart.svg';

import css from 'styles/components/landing/zaw/zawTextCallout.scss';
export interface Callout {
  icon: string;
  title?: string;
  body: string;
  image?: string;
}
export interface IZAWTextCalloutProps {
  slotDetails: {
    heading: string;
    callouts: Callout[];
    isLeftAligned?: boolean;
    isReducedStyling?: boolean;
  };
}

interface IIconMap {
  [key: string]: string;
}

const ICON_MAP: IIconMap = {
  dollarSlash: DollarSlashIcon,
  checklist: ChecklistIcon,
  shippingCar: ShippingCarIcon,
  circleSuccess: CheckmarkIcon,
  organization: Organization,
  tag: Tag,
  cart: Cart
};

const MOCK_CALLOUTS = [
  {
    icon: 'dollarSlash',
    title: 'No Program Fees',
    body: 'Program setup, implementation, maintenance, fulfillment, and accounting is included at no extra cost.'
  },
  {
    icon: 'checklist',
    title: 'Unrivaled Product Selection',
    body: 'We select trusted brands your employees know and love, that meet the most rigorous OSHA and ATSM standards.'
  },
  {
    icon: 'shippingCar',
    title: 'Free Shipping & Returns',
    body: 'Free Shipping for employee orders, 365-day returns, and easy exchanges.'
  }
];

export const ZAWTextCallout = (props: IZAWTextCalloutProps) => {
  const {
    slotDetails: { callouts = MOCK_CALLOUTS, heading, isLeftAligned = false, isReducedStyling = false }
  } = props;

  return (
    <div className={css.container}>
      {heading && <h2 className={cn(css.companyCalloutHeading, { [css.reducedFont]: isReducedStyling })}>{heading}</h2>}
      <div className={css.calloutContainer}>
        {callouts.map(({ icon, title, body, image }: Callout, index: number) => (
          <article className={css.callout} key={title ?? '' + index}>
            <img
              className={image ? css.calloutImage : cn(css.calloutLogo, { [css.reducedImage]: isReducedStyling })}
              src={image || ICON_MAP[icon]}
              alt=""
            />
            {!!title && <h3 className={cn(css.calloutHeading, { [css.leftAligned]: isLeftAligned })}>{title}</h3>}
            <p className={cn(css.calloutText, { [css.leftAligned]: isLeftAligned })}>{body}</p>
          </article>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary('ZAWTextCallout', ZAWTextCallout);
