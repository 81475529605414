import { BannerAdInTest } from './BannerAd';

import type { IpStatus, PageInfo } from 'types/contentSymphony';
import type { SlotDetails } from 'types/landing';

export interface BannerAdSlotProps {
  slotDetails: SlotDetails;
  slotName?: string;
  slotIndex?: number;
  pageName?: string;
  pageInfo?: PageInfo;
  shouldLazyLoad?: boolean;
  slotHeartsData?: boolean;
  ipStatus?: IpStatus;
  isFullWidth?: boolean;
}

export const BannerAdSlot = (props: BannerAdSlotProps) => {
  const { slotName, slotIndex } = props;
  return (
    <div className="my-6" data-slot-id={slotName} data-slotindex={slotIndex}>
      <BannerAdInTest slotIndex={slotIndex} />
    </div>
  );
};
