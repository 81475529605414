import PropTypes from 'prop-types';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { cleanUp } from 'helpers';
import useMartyContext from 'hooks/useMartyContext';

const FaqSingle = ({ answer, faqId, question }) => {
  const { testId } = useMartyContext();
  return (
    <dl data-test-id={testId('faqEntry')} id={faqId} itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
      <dt>
        <abbr title="Question">Q:</abbr>
        <h4 data-test-id={testId('faqQuestion')} itemProp="name">
          {question}
        </h4>
      </dt>
      <dd data-test-id={testId('faqAnswerWrapper')} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
        <abbr title="Answer">A:</abbr>
        {/* sanitize html returned from Mafia/Gateway Content Symphony API */}
        <div dangerouslySetInnerHTML={{ __html: cleanUp(answer) }} data-test-id={testId('faqAnswer')} itemProp="text" />
      </dd>
      <dd>
        <a href={'#faq-list'}>Return to Top</a>
      </dd>
    </dl>
  );
};

FaqSingle.contextTypes = {
  testId: PropTypes.func
};

export default withErrorBoundary('FaqSingle', FaqSingle);
