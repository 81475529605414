import React from 'react';

import { cn } from 'helpers/classnames';
import MelodyVideoPlayer from 'components/common/melodyVideo/MelodyVideoPlayer';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/zaw/zawVideoPlayer.scss';

export interface IZAWVideoPlayerProps {
  slotDetails: {
    themeKey: string;
    calloutHeading: string;
    calloutSubHeading: string;
    ctaLink: string;
    ctaText: string;
    src: string;
  };
}

enum THEME_KEYS {
  DARK = 'DARK',
  WHITE = 'WHITE'
}

export const ZAWVideoPlayer = (props: IZAWVideoPlayerProps) => {
  const {
    slotDetails,
    slotDetails: {
      themeKey = THEME_KEYS.DARK,
      src = 'https://m.media-amazon.com/images/G/01/ZAW/ZWLNDNGPG/zappos___overview_video___final___22-zap-16616_720p_1.mp4',
      calloutHeading = 'Custom Corporate Safety Programs for Any & All Needs',
      calloutSubHeading = 'Our team of safety experts have set up programs for companies with needs as diverse as yours. Contact us today and our safety team will follow up with a customized program just for you.',
      ctaLink = '/c/zappos-at-work-form',
      ctaText = 'Connect with a Team Member'
    } = {}
  } = props || {};

  return (
    <div className={cn(css.container, { [css.darkBackground]: themeKey === THEME_KEYS.DARK, [css.whiteBackground]: themeKey === THEME_KEYS.WHITE })}>
      <h2 className={css.calloutHeading}>{calloutHeading}</h2>
      <p className={css.calloutSubHeading}>{calloutSubHeading}</p>
      <MelodyVideoPlayer slotDetails={{ ...slotDetails, src }} />
      <a className={css.headerButton} href={ctaLink}>
        {ctaText}
      </a>
    </div>
  );
};

export default withErrorBoundary('ZAWVideoPlayer', ZAWVideoPlayer);
