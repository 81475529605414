import type { Mafia } from 'types/mafia';
import type { Cookies } from 'types/cookies';
import timedFetch from 'middleware/timedFetch';
import { sendJsonDataWithCreds } from 'apis/mafia/common';

export function chromeAbandonedCartRedeem(
  { url }: Mafia,
  chromeToken: any,
  credentials: Cookies = {},
  fetcher = timedFetch('chromeAbandonedCartRedeem')
) {
  return sendJsonDataWithCreds(
    `${url}/chrome-abandoned-cart/redeem`,
    {},
    {
      credentials,
      method: 'POST',
      fetcher,
      additionalHeaders: {
        'X-Chrome-Abandoned-Cart-Token': chromeToken,
        'X-Marty-Env-Id': window.location.hostname
      }
    }
  );
}
