import { connect } from 'react-redux';
import { useEffect, useState } from 'react';

import { cn } from 'helpers/classnames';
import { getAmethystPageType } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import { fetchSubscriptionsInfo, REQUEST_TYPE_VILLAGEIDIOT } from 'actions/account/subscriptions';
import { evOneClickSubscriptionRecord, evRecordEmailAddress } from 'events/landing';
import { handleOneClickSubList } from 'actions/landing/quickSubscription';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/zapposQuickSubscription.scss';

export const ZapposQuickSubscription = ({
  isCustomer,
  pageType,
  subscriptionsInfo,
  fetchSubscriptionsInfo,
  routeParams: requestSpecs,
  slotDetails = {},
  handleOneClickSubList
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSucceed, setSucceed] = useState(false);
  const [isErrored, setErrored] = useState(false);
  // heading1 and cta1 is for Email Input, heading2 and cta2 is for 1-button click
  const { heading, cta, subheading, listId, success, error, subMsg } = slotDetails;

  const cta1 = cta[0],
    cta2 = cta[1],
    heading1 = heading[0],
    heading2 = heading[1],
    subheading1 = subheading[0],
    subheading2 = subheading[1];

  const requestType = REQUEST_TYPE_VILLAGEIDIOT;

  useEffect(() => {
    if (isCustomer) {
      fetchSubscriptionsInfo({ requestSpecs, requestType });
    }
  }, [fetchSubscriptionsInfo, isCustomer, requestSpecs, requestType]);

  const emailAddressSubmitCall = e => {
    e.preventDefault();
    const emailAddress = e.target.email.value;
    const { listId } = slotDetails;
    handleOneClickSubList(listId, callAfterSubmitCallback, emailAddress);
  };

  const oneClickSubmitCall = e => {
    e.preventDefault();
    const { listId } = slotDetails;
    handleOneClickSubList(listId, callAfterSubmitCallback);
  };

  const callAfterSubmitCallback = (success, emailAddress) => {
    setIsSubmitted(true);
    if (success) {
      setSucceed(true);
      setErrored(false);
      if (emailAddress) {
        track(() => [evRecordEmailAddress, { listId, sourceName: getAmethystPageType(pageType), emailAddress }]);
      } else {
        track(() => [evOneClickSubscriptionRecord, { listId, sourceName: getAmethystPageType(pageType) }]);
      }
    } else {
      setSucceed(false);
      setErrored(true);
    }
  };

  const checkForSubscription = subscriptionsInfo => {
    const { listId } = slotDetails;
    let flag = false;
    if (isCustomer) {
      const {
        subscriptions: { emailLists }
      } = subscriptionsInfo;
      emailLists.map(emailList => {
        const { emailListId, subscribed } = emailList;
        if (emailListId === listId) {
          flag = subscribed;
        }
      });
    }
    return flag;
  };

  const renderLoggedInUnSubscribedCustomerUI = () => (
    <div className={cn(css.container2, { [css.submitted]: isSubmitted })}>
      <div className={css.heading2}>
        <p className={css.mainHeading}>{heading2}</p>
        <p className={css.subHeading}>{subheading2}</p>
      </div>
      <div className={css.buttonContent}>
        <button type="submit" className={css.oneClickBtn} data-eventvalue="Notify-Me" onClick={oneClickSubmitCall}>
          {cta2}
        </button>
      </div>
      {isSucceed && (
        <div className={css.success}>
          <p aria-hidden={!isSubmitted}>{success}</p>
        </div>
      )}
      {isErrored && !isSucceed && (
        <div className={css.success}>
          <p aria-hidden={!isSubmitted}>{error}</p>
        </div>
      )}
    </div>
  );

  const renderLoggedInSubscribedCustomerUI = () => {
    if (!subMsg) {
      return null;
    }
    return (
      <div className={css.successDiv}>
        <p>{subMsg}</p>
      </div>
    );
  };

  const renderLoggedOutCustomerUI = () => (
    <div className={cn(css.container2, { [css.submitted]: isSubmitted })}>
      <div className={css.heading2}>
        <p className={css.mainHeading}>{heading1}</p>
        <p className={css.subHeading}>{subheading1}</p>
      </div>
      <form method="post" onSubmit={emailAddressSubmitCall} className={css.container} action="/subscription.do">
        <div className={css.formContent}>
          <input required id="footerSubEmail" name="email" type="email" placeholder="Email Address" />
          <button className={css.emailAddBtn} type="submit">
            {cta1}
          </button>
        </div>
      </form>
      {isSucceed && (
        <div className={css.success}>
          <p aria-hidden={!isSubmitted}>{success}</p>
        </div>
      )}
      {isErrored && !isSucceed && (
        <div className={css.success}>
          <p aria-hidden={!isSubmitted}>{error}</p>
        </div>
      )}
    </div>
  );

  return (
    <>
      {isCustomer
        ? checkForSubscription(subscriptionsInfo)
          ? renderLoggedInSubscribedCustomerUI()
          : renderLoggedInUnSubscribedCustomerUI()
        : renderLoggedOutCustomerUI()}
    </>
  );
};

export function mapStateToProps(state) {
  return {
    isCustomer: !!state.cookies['x-main'],
    pageType: state.pageView.pageType,
    subscriptionsInfo: state.subscriptionsInfo
  };
}

const ConnectedZapposQuickSubscription = connect(mapStateToProps, {
  fetchSubscriptionsInfo,
  handleOneClickSubList
})(ZapposQuickSubscription);

export default withErrorBoundary('ZapposQuickSubscription', ConnectedZapposQuickSubscription);
