import SingleReview from 'components/common/SingleReview';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/landing/brandReviews.scss';

export const BrandReviews = props => {
  const {
    testId,
    marketplace: {
      features: { showReviews }
    }
  } = useMartyContext();
  const { slotDetails, onTaxonomyComponentClick, shouldLazyLoad } = props;
  const { reviews, brand } = slotDetails;

  if (reviews?.length && brand && showReviews) {
    const reviewsList = reviews.map(eachReview => {
      const { id } = eachReview;
      return <SingleReview key={id} singleReview={eachReview} onTaxonomyComponentClick={onTaxonomyComponentClick} shouldLazyLoad={shouldLazyLoad} />;
    });
    return (
      <div className={css.mainReviewsWrapper} data-test-id={testId('brandPageReviewsContainer')}>
        <h2>Latest {brand.name} Reviews</h2>
        {reviewsList}
      </div>
    );
  }
  return null;
};

export default withErrorBoundary('BrandReviews', BrandReviews);
