import React from 'react';

import LandingPageLink from 'components/landing/LandingPageLink';
import useMartyContext from 'hooks/useMartyContext';
import MelodyGrid from 'components/landing/MelodyGrid';
import MelodyCurated from 'components/landing/MelodyCurated';
import LandingProductCardWrapper from 'components/landing/LandingProductCardWrapper';
import ProductList from 'components/landing/ProductList';
import ProductGrid from 'components/common/ProductGrid';
import ProductCarousel from 'components/common/ProductCarousel';
import ProductResults from 'components/landing/ProductResults';
import ProductImageInline from 'components/landing/ProductImageInline';
import MarketRecos from 'components/search/MarketRecos';
import type { SlideWidths } from 'components/common/Carousel/Carousel.types';
import type { HeartProps } from 'types/hearts';

import css from 'styles/components/landing/productSearch.scss';

export interface Product {
  imageMap: { [key: string]: string };
  brandName: string;
  productId: string;
  originalPrice: string;
  colorId: string;
  msaImageUrl?: string;
  percentOff: string;
  productTypeFacet?: string;
  productUrlRelative?: string;
  productName: string;
  thumbnailImageUrl?: string;
  styleId: string;
  price: string;
  msaImageId?: string;
  productUrl: string;
  productRating: number;
  thumbnailImageUrlRelative?: string;
}

export interface SlotDetails {
  ctacopy: string;
  gae: string;
  title: string;
  ctalink: string;
  eventLabel: string;
  componentName: string;
  siteName: string;
  isCrossSiteSearch: boolean;
  style: string;
  products: Product[];
}

export interface ProductSearchProps {
  slotIndex: number;
  slotName: string;
  slotDetails: SlotDetails;
  slotHeartsData: HeartProps;
  onComponentClick: (evt: React.MouseEvent<HTMLAnchorElement>, product: Product) => void;
  shouldLazyLoad: boolean;
  isFullWidth: boolean;
  slideWidths?: SlideWidths;
}

export const ProductSearch = (props: ProductSearchProps) => {
  const {
    marketplace: {
      landing: { hasProductSearchCarousel }
    }
  } = useMartyContext();
  const { slotName, slotDetails, onComponentClick, shouldLazyLoad, slotHeartsData, isFullWidth, slideWidths } = props;

  const { style, eventLabel: eventLabelCustom, componentName, gae, ctacopy, ctalink } = slotDetails || {};
  const eventLabel = eventLabelCustom || style || componentName;
  const commonProps = { ...props, eventLabel, shouldLazyLoad };
  const melodyGridCommonProps = { ...commonProps };

  switch (style) {
    case 'melody-grid':
      return <MelodyGrid {...melodyGridCommonProps} />;
    case 'melody-grid-half':
      return <MelodyGrid {...melodyGridCommonProps} isHalf={true} />;
    case 'melody-curated':
      return <MelodyCurated {...commonProps} />;
    case 'image-inline':
      return <ProductImageInline {...commonProps} />;
    case 'product-list':
      return <ProductList {...commonProps} />;
    case 'product-grid':
      return <ProductGrid {...commonProps} />; // Does not support cross site label and modal
    case 'default-results':
      return <ProductResults {...commonProps} />;
    case 'marketplace-carousel':
      return <MarketRecos crossSiteRecos={slotDetails} />;
    case 'carousel':
      if (hasProductSearchCarousel) {
        return <ProductCarousel {...commonProps} />; // Does not support cross site label and modal
      }
    // eslint-disable-next-line no-fallthrough
    default: // style === 'melody' or no style property provided
      return (
        <div data-slot-id={slotName}>
          <LandingProductCardWrapper
            slotDetails={slotDetails}
            slotHeartsData={slotHeartsData}
            shouldLazyLoad={shouldLazyLoad}
            isFullWidth={isFullWidth}
            isCarousel={true}
            slideWidths={slideWidths}
          />
          {ctacopy && ctalink && (
            <LandingPageLink
              data-eventlabel={eventLabel}
              data-eventvalue={gae || ctacopy}
              className={css.cta}
              onClick={onComponentClick}
              url={ctalink}
            >
              {ctacopy}
            </LandingPageLink>
          )}
        </div>
      );
  }
};

export default ProductSearch;
