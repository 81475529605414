import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import timedFetch from 'middleware/timedFetch';
import { trackError } from 'helpers/ErrorUtils';
import type { IZAWFormData, IZAWFormStatus } from 'components/landing/ZAW/ZAWForm';
import { fetchOpts } from 'apis/mafia/common';

export function postZAWSubmit(
  data: IZAWFormData,
  setFormStatus: (value: IZAWFormStatus | undefined) => void,
  redirectUrl: string,
  fetcher = timedFetch('ZAWInterestForm')
) {
  const reqUrl = '/zaw/submit-info';
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  return fetcher(
    reqUrl,
    fetchOpts({
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
  )
    .then(fetchErrorMiddleware)
    .then(() => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
      setFormStatus('success');
    })
    .catch((e: Error) => {
      setFormStatus('error');
      trackError('ERROR', 'Unable to post ZAW Form', e);
    });
}
