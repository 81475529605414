import React from 'react';

import { RightArrowCircle } from 'components/icons';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/zaw/zawTaskList.scss';

export interface ZAWTask {
  name: string;
}

export interface ZAWTaskListProps {
  slotName?: string;
  slotIndex?: number;
  slotDetails: {
    tasks: ZAWTask[];
  };
}

const ZAWTaskList = (props: ZAWTaskListProps) => {
  const { slotDetails } = props;
  const { tasks } = slotDetails;
  return (
    <ul className={css.tasks}>
      {tasks.map((task, index) => (
        <li className={css.task} key={task.name + index}>
          <div className={css.svg}>
            <RightArrowCircle />
          </div>
          <div className={css.text}>{task.name}</div>
        </li>
      ))}
    </ul>
  );
};

export default withErrorBoundary('ZAWTaskList', ZAWTaskList);
