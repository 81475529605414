import React from 'react';

import { cn } from 'helpers/classnames';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/landing/zaw/zawCompanyCallout.scss';

export interface IZAWCompanyCalloutProps {
  slotDetails: {
    heading: string;
    company: [
      {
        img: string;
        name: string;
      }
    ];
    themeKey: string;
    headingStyle: string;
  };
}

const THEME_KEYS = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  WHITE: 'WHITE'
};

export const ZAWCompanyCallout = (props: IZAWCompanyCalloutProps) => {
  const {
    slotDetails: { heading = 'Companies that trust us', company, themeKey = THEME_KEYS.LIGHT, headingStyle }
  } = props;

  return (
    <div className={cn(css.container, { [css.darkBackground]: themeKey === THEME_KEYS.DARK, [css.whiteBackground]: themeKey === THEME_KEYS.WHITE })}>
      <h2 className={cn(css.heading, { [css.darkText]: themeKey === THEME_KEYS.DARK, [css.smallHeading]: headingStyle === 'small' })}>{heading}</h2>
      <div className={css.companyContainer}>
        {company?.map(({ img, name }, index) => <img className={css.companyImage} src={img} alt={name} key={index} />)}
      </div>
    </div>
  );
};

export default withErrorBoundary('ZAWCompanyCallout', ZAWCompanyCallout);
