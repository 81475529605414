import marketplace from 'cfg/marketplace.json';
import { subscribeToListZSub } from 'apis/mafia';
import { QUICK_SUBSCRIPTION_ERROR, QUICK_SUBSCRIPTION_SUCCESS } from 'constants/reduxActions';
import { getSubsiteId } from 'helpers/ClientUtils';
import { trackError } from 'helpers/ErrorUtils';
import { fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { selectMafiaConfig } from 'selectors/environment';

const { siteId } = marketplace;

export function setQuickSubscriptionSuccess() {
  return {
    type: QUICK_SUBSCRIPTION_SUCCESS
  };
}

export function setQuickSubscriptionError() {
  return {
    type: QUICK_SUBSCRIPTION_ERROR
  };
}

export function handleOneClickSubList(listId, callback, emailAddress) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);
    const subsiteId = getSubsiteId(marketplace);

    return subscribeToListZSub(
      mafiaConfig,
      {
        siteId,
        subsiteId,
        listIds: [listId],
        emailAddress
      },
      cookies
    )
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => {
        dispatch(setQuickSubscriptionSuccess());
        callback && callback(true, emailAddress);
      })
      .catch(err => {
        dispatch(setQuickSubscriptionError());
        callback && callback(false);
        trackError('ERROR', 'Quick Subscription Error', err);
      });
  };
}
