import queryString from 'query-string';

import {
  RECEIVE_SUBSCRIPTIONS_INFO,
  RECEIVE_SUBSCRIPTIONS_SIGNUP_CAPTCHA,
  RECEIVE_SUBSCRIPTIONS_SIGNUP_RESPONSE,
  REQUEST_SUBSCRIPTIONS_INFO,
  SUBSCRIPTION_BRAND_TOGGLED,
  SUBSCRIPTION_LIST_TOGGLED,
  SUBSCRIPTION_STOCK_TOGGLED,
  SUBSCRIPTION_UPCOMING_STYLE_TOGGLED
} from 'constants/reduxActions';
import { authenticationErrorCatchHandlerFactory } from 'actions/errors';
import {
  getSubscriptionMyAccount,
  getSubscriptionVillageIdiot,
  requestSubscriptionSignupCaptcha,
  submitSubscriptionSignup,
  unsubscribeFromAll,
  updateSubscriptionMyAccount,
  updateSubscriptionMyAccountOptOut,
  updateSubscriptionMyAccountUnsubscribeAll,
  updateSubscriptionVillageIdiot
} from 'apis/mafia';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware, fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { setSessionCookies } from 'actions/session';
import { prependAppRoot } from 'history/AppRootUtils';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { replaceAuthTokenSpace } from 'helpers/MyAccountUtils';
import { trackError } from 'helpers/ErrorUtils';
import { selectMafiaConfig } from 'selectors/environment';

export const REQUEST_TYPE_MYACCOUNT_TOKEN = 'token';
export const REQUEST_TYPE_MYACCOUNT_AUTH = 'auth';
export const REQUEST_TYPE_VILLAGEIDIOT = 'villageIdiot';
export const REQUEST_TYPE_SIGNUP = 'signup';
export const REQUEST_TYPE_SUCCESS_MESSAGE = 'success';

export function toggleSubscriptionList(emailListName) {
  return {
    type: SUBSCRIPTION_LIST_TOGGLED,
    selectedEmailListName: emailListName
  };
}

export function toggleSubscriptionBrand(id) {
  return {
    type: SUBSCRIPTION_BRAND_TOGGLED,
    selectedBrandId: id
  };
}

export function toggleSubscriptionStock(asin) {
  return {
    type: SUBSCRIPTION_STOCK_TOGGLED,
    selectedAsin: asin
  };
}

export function toggleSubscriptionUpcomingStyle(styleId) {
  return {
    type: SUBSCRIPTION_UPCOMING_STYLE_TOGGLED,
    selectedUpcomingStyleId: Number(styleId)
  };
}

export function requestSubscriptionsInfo() {
  return {
    type: REQUEST_SUBSCRIPTIONS_INFO
  };
}

export function recieveSubscriptionsSignupCaptcha(captcha) {
  return {
    type: RECEIVE_SUBSCRIPTIONS_SIGNUP_CAPTCHA,
    captcha
  };
}

export function recieveSubscriptionsSignupResponse(successfulSignup, errors = []) {
  return {
    type: RECEIVE_SUBSCRIPTIONS_SIGNUP_RESPONSE,
    successfulSignup,
    errors
  };
}

export function receiveSubscriptionsInfo(response, requestType) {
  if (requestType === REQUEST_TYPE_VILLAGEIDIOT) {
    const subscriptions = response;
    return {
      type: RECEIVE_SUBSCRIPTIONS_INFO,
      subscriptions
    };
  } else {
    if (response.optout) {
      return {
        type: RECEIVE_SUBSCRIPTIONS_INFO,
        optout: true
      };
    }
    return {
      type: RECEIVE_SUBSCRIPTIONS_INFO,
      subscriptions: {
        emailLists: response.emailLists
      }
    };
  }
}

export function translateSubscriptionDataForMyAccount(subscriptionsDelta) {
  const changes = [];
  Object.values(subscriptionsDelta).forEach(emailLists => {
    emailLists.forEach(({ emailListId, emailListName, subscribed }) => {
      changes.push({
        emailListId,
        emailListName,
        subscribed
      });
    });
  });
  return {
    brandSubscriptions: [],
    emailSubscriptions: changes,
    stockSubscriptions: []
  };
}

function generateZSubscriptionArgs(state) {
  const {
    cookies,
    router: {
      location: { search }
    }
  } = state;
  const mafiaConfig = selectMafiaConfig(state);
  const { auth_token: emailAuthToken } = queryString.parse(search) || {};
  return [mafiaConfig, cookies, { auth_token: replaceAuthTokenSpace(emailAuthToken) }];
}

export function generateFetchSubscriptionsApiCall(requestType, requestSpecs, state) {
  const { cookies } = state;
  const mafiaConfig = selectMafiaConfig(state);

  switch (requestType) {
    case REQUEST_TYPE_VILLAGEIDIOT:
      return getSubscriptionVillageIdiot(...generateZSubscriptionArgs(state));
    case REQUEST_TYPE_MYACCOUNT_AUTH:
      return getSubscriptionMyAccount(mafiaConfig, cookies);
    case REQUEST_TYPE_MYACCOUNT_TOKEN:
      return getSubscriptionMyAccount(mafiaConfig, {}, requestSpecs);
    default:
      return;
  }
}

export function generateUpdateSubscriptionsApiCall(requestType, { subscriptionsDelta, optout }, unsubscribeAll, requestSpecs, state) {
  const { cookies } = state;
  const mafiaConfig = selectMafiaConfig(state);

  if (optout) {
    return unsubscribeAll
      ? updateSubscriptionMyAccountUnsubscribeAll(mafiaConfig, cookies, requestSpecs)
      : updateSubscriptionMyAccountOptOut(mafiaConfig, cookies, requestSpecs.token);
  }
  switch (requestType) {
    case REQUEST_TYPE_VILLAGEIDIOT:
      return unsubscribeAll
        ? unsubscribeFromAll(...generateZSubscriptionArgs(state))
        : updateSubscriptionVillageIdiot(subscriptionsDelta, ...generateZSubscriptionArgs(state));
    case REQUEST_TYPE_SIGNUP:
      // TODO handle logged in and email-based signups
      return;
    case REQUEST_TYPE_MYACCOUNT_AUTH:
      return unsubscribeAll
        ? unsubscribeFromAll(mafiaConfig, cookies)
        : updateSubscriptionMyAccount(translateSubscriptionDataForMyAccount(subscriptionsDelta), mafiaConfig, cookies);
    case REQUEST_TYPE_MYACCOUNT_TOKEN:
      return unsubscribeAll
        ? updateSubscriptionMyAccountUnsubscribeAll(mafiaConfig, cookies, requestSpecs)
        : updateSubscriptionMyAccount(translateSubscriptionDataForMyAccount(subscriptionsDelta), mafiaConfig, {}, requestSpecs);
  }
}

export function fetchSubscriptionsSignupCaptcha(useCaptcha = true) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const req = useCaptcha
      ? requestSubscriptionSignupCaptcha(mafiaConfig)
          .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
          .then(fetchApiNotAuthenticatedMiddleware)
          .then(fetchErrorMiddleware)
      : Promise.resolve(null);
    return req
      .then(resp => dispatch(recieveSubscriptionsSignupCaptcha(resp)))
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/subscriptions', location)));
  };
}

export function updateSubscriptionResponse(response, requestType) {
  response.emailLists = response.emailSubscriptions;
  delete response.emailSubscriptions;
  if (requestType === REQUEST_TYPE_MYACCOUNT_TOKEN) {
    response.optout = response.isOptOut;
    delete response.isOptOut;
  }
  return response;
}

export function fetchSubscriptionsInfo({ requestSpecs = {}, requestType }) {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionsInfo());
    const {
      router: { location }
    } = getState();
    return generateFetchSubscriptionsApiCall(requestType, requestSpecs, getState())
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => dispatch(receiveSubscriptionsInfo(updateSubscriptionResponse(response, requestType), requestType)))
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/subscriptions', location)));
  };
}

export function updateSubscriptions(subscriptionInfo, unsubscribeAll, { requestSpecs = {}, requestType }) {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionsInfo());
    const {
      router: { location }
    } = getState();
    return generateUpdateSubscriptionsApiCall(requestType, subscriptionInfo, unsubscribeAll, requestSpecs, getState())
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => {
        dispatch(recieveSubscriptionsSignupResponse(true));
        dispatch(fetchSubscriptionsInfo({ requestSpecs, requestType }));
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/subscriptions', location)));
  };
}

export function signupForMailingList(recipientEmail, answer, token) {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionsInfo());

    const state = getState();
    const { cookies } = getState();
    const mafiaConfig = selectMafiaConfig(state);

    return submitSubscriptionSignup(mafiaConfig, cookies, {
      recipientEmail,
      answer,
      token
    })
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => dispatch(recieveSubscriptionsSignupResponse(true)))
      .catch(e => {
        trackError('NON-FATAL', 'Could not make Captcha protected API Call.', e);
        dispatch(fetchSubscriptionsSignupCaptcha());
        // TODO create captcha error middleware when captcha functionality is consolidated.
        dispatch(recieveSubscriptionsSignupResponse(false, e.status === 401 ? ['captcha'] : ['email']));
      });
  };
}
