import React from 'react';

import type { AccordionItemProps, AccordionProps } from 'components/common/MelodyAccordion';
import { Accordion, AccordionItem } from 'components/common/MelodyAccordion';

import css from 'styles/components/landing/landingPageAccordion.scss';

export const LandingPageAccordionItem = (props: AccordionItemProps) => (
  <AccordionItem {...props} accordionRegionContainerStyleOverride={css.landingPageAccordionItem} />
);

export const LandingPageAccordion = (props: AccordionProps) => {
  const { children } = props;
  return <Accordion {...props}>{children}</Accordion>;
};
