import { deepEqual } from 'fast-equals';
import { memo } from 'react';

import { cn } from 'helpers/classnames';
import { NonMemoImage } from 'components/common/Image';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { makeHashFromString } from 'helpers/index';

// static media queries are ok, they align with what is returned in the data.
// this is somewhat temporary, as we redesign image components to match Figma, we'd prefer static aspect ratios rather than being so dynamic.

const makeAspectRatioStyleInnerHtml = ({ sources, img, fullWidth }) => {
  let mediaStyles = '';
  // do not cap out fullWidth images.
  const makeMax = width => (!fullWidth && width ? `max-width: ${width}px; ` : '');
  let hasAspect = false;
  for (const srcdef of sources || []) {
    hasAspect ||= !!srcdef?.aspectratio;
    if (srcdef?.aspectratio && srcdef?.width && srcdef?.media) {
      mediaStyles += ` @media ${srcdef.media} { #--idplaceholder-- { aspect-ratio: ${srcdef.aspectratio}; ${makeMax(srcdef.width)}}}`;
    }
  }

  if (mediaStyles || (img?.aspectratio && img?.width)) {
    let style = `#--idplaceholder-- { width: 100%; `;
    if (img?.aspectratio) {
      hasAspect = true;
      style += `aspect-ratio: ${img.aspectratio}; ${makeMax(img.width)}} `;
    } else {
      style += '}';
    }
    const intermediate = style + mediaStyles;
    const id = makeHashFromString(intermediate);
    const final = intermediate.split('--idplaceholder--').join(id);
    return [id, final, hasAspect];
  }
  return [];
};

/**
 * Takes a sources array from ZCS, and other properties to construct a sources array
 *
 * @param sources
 * @param mobilesrc
 * @param mobileretina
 * @param tabletsrc
 * @param tabletretina
 * @param srcset
 * @param src
 * @param retina
 * @param srcX
 * @param srcY
 * @param retinaX
 * @param retinaY
 * @return {{nonDefaultSources, defaultSource}}
 */
function extractDefaultAndSources(
  sources,
  mobilesrc,
  mobilesrcX,
  mobilesrcY,
  mobileretina,
  mobileretinaX,
  mobileretinaY,
  tabletsrc,
  tabletsrcX,
  tabletsrcY,
  tabletretina,
  tabletretinaX,
  tabletretinaY,
  srcset,
  src,
  srcX,
  srcY,
  retina,
  retinaX,
  retinaY
) {
  const srcsetFrom = s => {
    let ret = '';
    for (const [img, spec] of s) {
      if (img) {
        if (ret) {
          ret += ', ';
        }
        ret += img;
        if (spec) {
          ret += ' ';
          ret += spec;
        }
      }
    }
    return ret;
  };
  const aspectRatioFrom = o => {
    const stdAspect = o?.std?.x && o?.std?.y && o?.std?.x / o?.std?.y;
    const retAspect = o?.ret?.x && o?.ret?.y && o?.ret?.x / o?.ret?.y;
    return stdAspect &&
      retAspect && // if both are present
      (stdAspect < 1 && retAspect < 1
        ? Math.abs(1 / stdAspect - 1 / retAspect) > 0.02 // invert and compare if both less than one.
        : Math.abs(stdAspect - retAspect) > 0.02) // otherwise just compare
      ? undefined // aspect ratios are different,  we can not apply an aspect ratio
      : stdAspect || retAspect; // aspect ratios are the same, we can apply the aspect ratio
  };

  // if the sources array does not exist, build it.
  if (!sources?.length) {
    // ZCS provides sources now, should very rarely get here.
    sources = [];
    if (mobilesrc || mobileretina) {
      const source = {
        media: `(max-width: 650px)`,
        srcset: srcsetFrom([
          [mobilesrc, '1x'],
          [mobileretina, '2x']
        ])
      };
      if (mobilesrcX) {
        source.width = mobilesrcX;
      }
      if (mobilesrcY) {
        source.height = mobilesrcY;
      }
      source.aspectratio = aspectRatioFrom({
        std: { x: mobilesrcX, y: mobilesrcY },
        ret: { x: mobileretinaX, y: mobileretinaY }
      })?.toFixed(4);
      if (source.aspectratio) {
        if (mobilesrcX) {
          source.width = mobilesrcX;
        }
        if (mobilesrcY) {
          source.height = mobilesrcY;
        }
      }
      sources.push(source);
    }
    if (tabletsrc || tabletretina) {
      const source = {
        media: `(max-width: 1024px)`,
        srcset: srcsetFrom([
          [tabletsrc, '1x'],
          [tabletretina, '2x']
        ])
      };
      source.aspectratio = aspectRatioFrom({
        std: { x: tabletsrcX, y: tabletsrcY },
        ret: { x: tabletretinaX, y: tabletretinaY }
      })?.toFixed(4);
      if (source.aspectratio) {
        if (tabletsrcX) {
          source.width = tabletsrcX;
        }
        if (tabletsrcY) {
          source.height = tabletsrcY;
        }
      }
      sources.push(source);
    }
    if (src || retina) {
      const source = {
        media: '',
        srcset: srcsetFrom([
          [src, '1x'],
          [retina, '2x']
        ])
      };
      source.aspectratio = aspectRatioFrom({
        std: { x: srcX, y: srcY },
        ret: { x: retinaX, y: retinaY }
      })?.toFixed(4);
      if (source.aspectratio) {
        if (srcX) {
          source.width = srcX;
        }
        if (srcY) {
          source.height = srcY;
        }
      }
      sources.push(source);
    }
  }

  let defaultSource = null;
  for (const source of sources) {
    if (!source.media) {
      defaultSource = source;
      break;
    }
  }

  const nonDefaultSources = [];
  for (const source of sources) {
    if (source.media) {
      nonDefaultSources.push(source);
    }
  }

  // if no default entry exits, then use the older "desktop src"
  if (!defaultSource) {
    defaultSource = {};
  }

  // if we do not have a default source in the sources array, then
  // create one from the older variables.  This is for the ZCS transition period
  // where we add the desktop image into sources.
  if (!defaultSource.srcset) {
    defaultSource.srcset =
      srcset ||
      srcsetFrom([
        [src, '1x'],
        [retina, '2x']
      ]);
    defaultSource.aspectratio = aspectRatioFrom({
      std: { x: srcX, y: srcY },
      ret: { x: retinaX, y: retinaY }
    })?.toFixed(4);
    if (defaultSource.aspectratio) {
      if (srcX) {
        defaultSource.width = srcX;
      }
      if (srcY) {
        defaultSource.height = srcY;
      }
    }
  }

  // if no src element is set, rip the first one out of the default sourceset.
  if (!defaultSource.src) {
    const trimmed = defaultSource?.srcset?.trim();
    const split = trimmed?.split(/\s+/, 1);
    defaultSource.src = split?.[0] || '';
  }
  return { nonDefaultSources, defaultSource };
}

/**
 * Render images with different src files depending on what SiteMerch populates in Symphony
 */
export const LandingPageImage = ({
  src = null,
  srcset = null,
  mobilesrc = null,
  tabletsrc = null,
  retina = null,
  mobileretina = null,
  tabletretina = null,
  sources = null,
  alt = null,
  title = null,
  width = null,
  height = null,
  role = null,
  itemProp = null,
  shouldLazyLoad = false,
  className = null,
  imgTestId = null,
  isFullWidth = false,
  src_x: srcX,
  src_y: srcY,
  retinasrc_x: retinaX,
  retinasrc_y: retinaY,
  mobilesrc_x: mobilesrcX,
  mobilesrc_y: mobilesrcY,
  tabletsrc_x: tabletsrcX,
  tabletsrc_y: tabletsrcY,
  fetchPriority
}) => {
  const { nonDefaultSources, defaultSource } = extractDefaultAndSources(
    sources,
    mobilesrc,
    mobilesrcX,
    mobilesrcY,
    mobileretina,
    null,
    null,
    tabletsrc,
    tabletsrcX,
    tabletsrcY,
    tabletretina,
    null,
    null,
    srcset,
    src,
    srcX,
    srcY,
    retina,
    retinaX,
    retinaY
  );

  if (!defaultSource.src) {
    return null;
  }

  // build the media-selector sources.
  const pictureProps = [];
  for (const source of nonDefaultSources) {
    if (source.media && source.srcset) {
      pictureProps.push(<source key={source.srcset + source.media} media={source.media} srcSet={source.srcset} />);
    }
  }

  const imgProps = {
    'src': defaultSource.src,
    title,
    width,
    height,
    'srcSet': defaultSource.srcset,
    role,
    itemProp,
    'alt': !alt || alt === '""' ? '' : alt,
    'data-test-id': imgTestId,
    'fetchpriority': fetchPriority
  };

  /*
   *  * if we are using mobile/tables sources, then compute the aspect ratios, otherwise, don't include them.
   *  * if we have both desktop std and retina sources, then compare the aspect ratios, and use them if they match
   *  * if we have only one std or retina source, then use that aspect ratio
   */

  // returns null if no style, otherwise returns the aspect ratio style for the layout, if it is defined
  // we now only support the "sources" array for mobile and tablet images.
  const [imgId, aspectRatioStyle, hasAspect] = makeAspectRatioStyleInnerHtml({
    sources: nonDefaultSources,
    img: defaultSource,
    fullWidth: isFullWidth
  });

  return (
    <>
      {aspectRatioStyle ? <style dangerouslySetInnerHTML={{ __html: aspectRatioStyle }} /> : undefined}
      <NonMemoImage
        id={imgId}
        {...imgProps}
        className={cn(className)}
        pictureProps={pictureProps}
        loading={shouldLazyLoad ? 'lazy' : undefined}
        hasAspect={hasAspect}
        isFullWidth={isFullWidth}
      />
    </>
  );
};

export default memo(withErrorBoundary('LandingPageImage', LandingPageImage), deepEqual);
