import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { triggerAssignment } from 'actions/ab';
import { isValidTestTreatment } from 'helpers/SymphonyHydraTestValidator';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import Ad from 'components/landing/Ad';
import Agreement from 'components/landing/Agreement';
import BrandReviews from 'components/landing/BrandReviews';
import BrandTrending from 'components/landing/BrandTrending';
import BrokenPage from 'components/error/BrokenPage';
import ChromeAbandonedCartHeader from 'components/landing/ChromeAbandonedCartHeader';
import Departments from 'components/landing/Departments';
import EGiftCard from 'components/landing/EGiftCard';
import EventCallout from 'components/landing/EventCallout';
import Faqs from 'components/landing/Faqs';
import GenericBrandFacets from 'components/landing/GenericBrandFacets';
import Iframe from 'components/landing/Iframe';
import Images from 'components/landing/Images';
import MelodyArticleImageCopy from 'components/landing/MelodyArticleImageCopy';
import MelodyArticleImages from 'components/landing/MelodyArticleImages';
import MelodyBrandIndex from 'components/landing/MelodyBrandIndex';
import MelodyCategory from 'components/landing/MelodyCategory';
import MelodyEditorialPromo from 'components/landing/MelodyEditorialPromo';
import MelodyHeaderRule from 'components/landing/MelodyHeaderRule';
import MelodyHero from 'components/landing/MelodyHero';
import MelodyHeroFull from 'components/landing/MelodyHeroFull';
import MelodyHorizontalNav from 'components/landing/MelodyHorizontalNav';
import MelodyNewsfeed from 'components/landing/newsfeed/MelodyNewsfeed';
import MelodyPersonalizedBrand from 'components/landing/MelodyPersonalizedBrand';
import MelodyPersonalizedCategories from 'components/landing/MelodyPersonalizedCategories';
import MelodyPromoGroup from 'components/landing/MelodyPromoGroup';
import MelodySizingGuide from 'components/landing/MelodySizingGuide';
import MelodySplitEditorial from 'components/landing/MelodySplitEditorial';
import MelodyVerticalNav from 'components/landing/MelodyVerticalNav';
import MelodyVideoPlayer from 'components/common/melodyVideo/MelodyVideoPlayer';
import NotificationSignup from 'components/landing/NotificationSignup';
import PageContent from 'components/landing/PageContent';
import ProductSearch from 'components/landing/ProductSearch';
import Recommender from 'components/landing/Recommender';
import ReleaseCalendar from 'components/landing/ReleaseCalendar';
import ShopTheLook from 'containers/landing/ShopTheLook';
import VipDashboardHeader from 'components/landing/VipDashboardHeader';
import VipOptIn from 'components/landing/VipOptIn';
import VipPrimeLink from 'components/landing/VipPrimeLink';
import ZapposForm from 'components/landing/ZapposForm';
import ZapposHero from 'components/landing/ZapposHero';
import ZapposPromoGroup from 'components/landing/ZapposPromoGroup';
import ZapposQuickSubscription from 'components/landing/ZapposQuickSubscription';
import ZAWAccordion from 'components/landing/ZAW/ZAWAccordion';
import ZAWCompanyCallout from 'components/landing/ZAW/ZAWCompanyCallout';
import ZAWConfirmationCallout from 'components/landing/ZAW/ZAWConfirmationCallout';
import ZAWForm from 'components/landing/ZAW/ZAWForm';
import ZAWFormStandalone from 'components/landing/ZAW/ZAWFormStandalone';
import ZAWImageAndCallout from 'components/landing/ZAW/ZAWImageAndCallout';
import ZAWTaskList from 'components/landing/ZAW/ZAWTaskList';
import ZAWTestimonials from 'components/landing/ZAW/ZAWTestimonials';
import ZAWTextCallout from 'components/landing/ZAW/ZAWTextCallout';
import ZAWVideoPlayer from 'components/landing/ZAW/ZAWVideoPlayer';
import ZAWModernForm from 'components/landing/ZAW/ZAWModernForm';
import SurveyForm from 'components/survey/SurveyForm';
import VideoPlayerWrapper from 'components/common/VideoPlayer/VideoPlayerWrapper';
import { BannerAdSlot } from 'components/common/BannerAd/BannerAdSlot';

export const SOREL_FORM = 'SorelForm';

// The keys here map directly to the component name in content symphony.
const slotContentTypes = {
  ad: Ad,
  agreement: Agreement,
  bannerAd: BannerAdSlot,
  brandNotification: NotificationSignup,
  BrokenPage: BrokenPage,
  chromeAbandonedCartHeader: ChromeAbandonedCartHeader,
  departments: Departments,
  egiftcard: EGiftCard,
  eventCallout: EventCallout,
  faqs: Faqs,
  genericBrandAbout: PageContent, // reusing PageContent for Taxonomy pages.
  genericBrandEmails: NotificationSignup, // reusing Notifications for Taxonomy pages
  genericBrandFacets: GenericBrandFacets,
  genericBrandReviews: BrandReviews,
  genericBrandTrending: BrandTrending,
  iframe: Iframe,
  images: Images,
  melodyArticleImageCopy: MelodyArticleImageCopy,
  melodyArticleImages: MelodyArticleImages,
  melodyBrandIndex: MelodyBrandIndex,
  melodyCategory: MelodyCategory,
  melodyEditorialPromo: MelodyEditorialPromo,
  melodyHeaderRule: MelodyHeaderRule,
  melodyHero: MelodyHero,
  melodyHeroFull: MelodyHeroFull,
  melodyHorizontalNav: MelodyHorizontalNav,
  melodyNewsFeed: MelodyNewsfeed,
  melodyPersonalizedBrand: MelodyPersonalizedBrand,
  melodyPersonalizedCategories: MelodyPersonalizedCategories,
  melodyPromoGroup: MelodyPromoGroup,
  melodySizingGuide: MelodySizingGuide,
  melodySplitEditorial: MelodySplitEditorial,
  melodyVerticalNav: MelodyVerticalNav,
  melodyVideoPlayer: MelodyVideoPlayer,
  pageContent: PageContent,
  productSearch: ProductSearch,
  recommender: Recommender,
  releaseCalendar: ReleaseCalendar,
  shopTheLook: ShopTheLook,
  [SOREL_FORM]: SurveyForm,
  VideoPlayer: VideoPlayerWrapper,
  vipDashboardHeader: VipDashboardHeader,
  vipOptIn: VipOptIn,
  vipPrimeLink: VipPrimeLink,
  ZapposForm: ZapposForm,
  zapposHero: ZapposHero,
  zapposPromoGroup: ZapposPromoGroup,
  ZapposQuickSubscription: ZapposQuickSubscription,
  ZAWAccordion: ZAWAccordion,
  ZAWCompanyCallout: ZAWCompanyCallout,
  ZAWConfirmationCallout: ZAWConfirmationCallout,
  ZAWForm: ZAWForm,
  ZAWFormStandalone: ZAWFormStandalone,
  ZAWImageAndCallout: ZAWImageAndCallout,
  ZAWTaskList: ZAWTaskList,
  ZAWTestimonials: ZAWTestimonials,
  ZAWTextCallout: ZAWTextCallout,
  ZAWVideoPlayer: ZAWVideoPlayer,
  ZAWModernForm: ZAWModernForm
};

const renderSlot = (testTreatment, assignmentGroup, testName) => {
  if (assignmentGroup !== null) {
    return testTreatment[assignmentGroup] === 'Render';
  }
  return !testName;
};

export const LandingSlot = props => {
  const {
    isRecognized,
    triggerAssignment,
    hasAssignmentTriggered,
    pageName,
    pageInfo,
    slotName,
    data = {},
    onTaxonomyComponentClick,
    onComponentClick,
    shouldLazyLoad,
    slotHeartsData,
    ipStatus,
    slotContentTypesList = slotContentTypes,
    slotIndex,
    isFullWidth,
    slideWidths
  } = props;

  const { componentName, testName, testTreatment, testTrigger } = data;

  /*
   * Components can be reassigned through the Symphony testTreatment
   * attribute. Setting up initialized variables for reassignment
   * if needed.
   */
  let componentContent = data;
  let componentContentName = componentContent.componentName;

  /*
   * Hook definition to set up test assignment for a Symphony component.
   * Evaluates the test attributes and trigger criteria before
   * assigning user to test. setShouldShow to true at this point
   * to avoid showing initial component, then swapping it on the next
   * tick with the treatment or fallback component.
   */
  useEffect(() => {
    const validTest = isValidTestTreatment({
      testName,
      testTrigger,
      testTreatment,
      isRecognized,
      hasAssignmentTriggered
    });

    if (validTest) {
      const { index = 0 } = triggerAssignment(testName) || {};
      setAssignmentGroup(index);
    }
    setShouldShow(true);
  }, [hasAssignmentTriggered, isRecognized, testName, testTreatment, testTrigger, triggerAssignment]);

  const [assignmentGroup, setAssignmentGroup] = useState(null);
  const shouldRenderSlot = renderSlot(testTreatment, assignmentGroup, testName);
  const [shouldShow, setShouldShow] = useState(shouldRenderSlot);
  if (!componentName) {
    return null;
  }

  /*
   * Checking for a testTreatment. If the assignmentGroup aligns
   * with a key containing a component object, overwrite the component data.
   * Else if there is no assignment, but there is a fallback component object,
   * overwrite with that. If there is a testName and no testTreatment assignment
   * or the assigment explicitly says not to render, set the component name to null.
   */
  if (testName && testTrigger && testTreatment) {
    const { fallback } = testTreatment;
    if (testTreatment[assignmentGroup] instanceof Object) {
      componentContent = testTreatment[assignmentGroup];
      componentContentName = testTreatment[assignmentGroup].componentName;
    } else if (!hasAssignmentTriggered && fallback instanceof Object) {
      componentContent = fallback;
      componentContentName = fallback.componentName;
    } else if ((!testTreatment[assignmentGroup] && !fallback) || testTreatment[assignmentGroup] === 'DoNotRender') {
      componentContentName = null;
    }
  }
  const SlotContent = slotContentTypesList[componentContentName];

  return SlotContent && (shouldRenderSlot || shouldShow) ? (
    <SlotContent
      slotName={slotName}
      slotDetails={componentContent}
      slotIndex={slotIndex}
      pageName={pageName}
      pageInfo={pageInfo}
      onComponentClick={onComponentClick}
      onTaxonomyComponentClick={onTaxonomyComponentClick}
      shouldLazyLoad={shouldLazyLoad}
      slotHeartsData={slotHeartsData}
      ipStatus={ipStatus}
      isFullWidth={isFullWidth}
      slideWidths={slideWidths}
    />
  ) : null;
};

const mapStateToProps = (state, ownProps) => ({
  isRecognized: !!state.cookies['x-main'],
  hasAssignmentTriggered: Object.keys(state.ab.assignments).includes(ownProps.data?.testName)
});

const mapDispatchToProps = {
  triggerAssignment
};

const ConnectedLandingSlot = connect(mapStateToProps, mapDispatchToProps)(LandingSlot);
export default withErrorBoundary('LandingSlot', ConnectedLandingSlot);
