import React from 'react';

import LandingPageLink from 'components/landing/LandingPageLink';
import { LandingPageAccordion, LandingPageAccordionItem } from 'components/landing/LandingPageAccordion';
import type { AccordionItemProps } from 'components/common/MelodyAccordion';
import useMartyContext from 'hooks/useMartyContext';
import { stripSpecialChars } from 'helpers/index';

import css from 'styles/components/landing/melodyVerticalNav.scss';

interface IMelodyVerticalNavProps {
  slotName: string;
  slotDetails: {
    sections: [
      {
        sectionHeight?: string;
        headingtext: string;
        links: [{ link: string; gae: string; text: string }];
      }
    ];
  };
  slotIndex: number;
  onComponentClick: () => void;
}

const MelodyVerticalNav = (props: IMelodyVerticalNavProps) => {
  const {
    slotName,
    slotDetails: { sections },
    onComponentClick,
    slotIndex
  } = props;

  const accordionSections: React.ReactElement<AccordionItemProps>[] = [];
  const { testId } = useMartyContext();

  sections.map(navSection => {
    const { headingtext, links, sectionHeight } = navSection;
    accordionSections.push(
      <LandingPageAccordionItem key={headingtext} heading={headingtext} accordionTestId={headingtext}>
        <section style={sectionHeight ? { maxHeight: sectionHeight } : {}} className={css.linksSection} aria-label={headingtext}>
          <ul data-test-id={testId('melodyVerticalNavDropDown')}>
            {links.map((individualLink, index) => {
              const { link, gae, text } = individualLink;
              const linkProps = {
                'onClick': onComponentClick,
                'data-eventlabel': 'melodyVerticalNav',
                'data-eventvalue': gae,
                'data-slotindex': slotIndex
              };
              return (
                <li key={`${stripSpecialChars(link)}_${index}`}>
                  <LandingPageLink url={link} {...linkProps}>
                    {text}
                  </LandingPageLink>
                </li>
              );
            })}
          </ul>
        </section>
      </LandingPageAccordionItem>
    );
  });

  return (
    <aside className={'facetWrapper facets'}>
      {accordionSections.length > 0 && (
        <LandingPageAccordion data-slot-id={slotName} openMultiple defaultOpenAll>
          {accordionSections}
        </LandingPageAccordion>
      )}
    </aside>
  );
};

export default MelodyVerticalNav;
