import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import queryString from 'query-string';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { checkCustomerAuthentication } from 'actions/authentication';
import { setError } from 'actions/errors';
import { Loader } from 'components/Loader';
import useEffectOnce from 'hooks/useEffectOnce';
import { selectMafiaConfig } from 'selectors/environment';
import { chromeAbandonedCartRedeem } from 'apis/mafia/abandon';

import css from 'styles/components/landing/chromeAbandonedCartHeader.scss';

export const ChromeAbandonedCartHeader = props => {
  const {
    slotDetails,
    location,
    cookies,
    mafiaUrl,
    checkCustomerAuthentication,
    setError,
    chromeAbandonedCartRedeemApiCall = chromeAbandonedCartRedeem
  } = props;
  const { pathname, search } = location;
  const { pv3 } = queryString.parse(search) || {};
  const returnTo = `${pathname}${search}`;

  const [dataKey, setDataKey] = useState(null);

  useEffectOnce(() => {
    const redeemPromoIfApplicable = () => {
      if (pv3) {
        chromeAbandonedCartRedeemApiCall({ url: mafiaUrl }, pv3, cookies).then(res => {
          setKeyFromStatusCode(res.status);
        });
      } else {
        setDataKey('noDiscount');
      }
    };
    checkCustomerAuthentication(returnTo, () => redeemPromoIfApplicable(), {
      redirectOnClose: '/'
    });
  });

  const setKeyFromStatusCode = statusCode => {
    const mapping = {
      200: 'success',
      401: 'error'
    };
    if (statusCode in mapping) {
      setDataKey(mapping[statusCode]);
    } else {
      setError('Unexpectd error received from ChromeAbandonedCart Lambda', null, 500);
    }
  };

  if (!dataKey) {
    return <Loader centered={true} />;
  }

  const { heading, subCopy, ctaTitle, ctaLink } = slotDetails[dataKey] || {};

  return (
    <div className={css.container}>
      {heading && <h1 className={css.header}>{heading}</h1>}
      {subCopy && <p className={css.details}>{subCopy}</p>}
      {ctaTitle && ctaLink && (
        <Link to={ctaLink} className={css.link}>
          {ctaTitle}
        </Link>
      )}
    </div>
  );
};

export const mapDispatchToProps = {
  checkCustomerAuthentication,
  setError
};

export const mapStateToProps = state => {
  const {
    router: { location },
    cookies
  } = state;
  const mafiaConfig = selectMafiaConfig(state);

  return {
    location,
    cookies,
    mafiaUrl: mafiaConfig.url
  };
};

const ConnectedChromeAbandonedCartHeader = connect(mapStateToProps, mapDispatchToProps)(ChromeAbandonedCartHeader);

export default withErrorBoundary('ChromeAbandonedCartHeader', ConnectedChromeAbandonedCartHeader);
